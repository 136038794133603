import { useState, useEffect, useContext, useReducer } from 'react';
import Round from './Round';
import LanguageSelector from '../LanguageSelector';
import { ErrorBoundary } from '../../../ErrorBoundary';
import { ApiContext } from '../../context/ApiContext';
import { LanguageContext } from '../../context/LanguageContext';
import UserData from '../../entity/UserDataClass';
import * as UserService from '../../service/UserService'
import * as RoundService from '../../service/RoundService'
import { consumeApi } from '../../service/ApiService'
import Loader from '../Loader';
import {ROUNDS_FREQUENCY_TO_PROMPT_REGISTRATION} from '../../Constants';


function Game(): JSX.Element {

    const { user, dispatchUser } = useContext(ApiContext);
    const { lang } = useContext(LanguageContext);
    const [round, dispatchRound] = useReducer(
        RoundService.reduceRound,
        null
    )
    const [roundsCount, setRoundsCount] = useState<number>(0);
    // const [isFirstRoundKeyboardClicked, setIsFirstRoundKeyboardClicked] = useState<boolean>(false);
    const [isTutorialDone, setIsTutorialDone] = useState<boolean>(false);
    const [isPromptableToAskToRegistar, setIsPromptableToAskToRegistar] = useState<boolean | undefined>(false);
    const [error, setError] = useState<any>();

    useEffect(() => {
        console.log("User has changed ", user)
        if (user && user.userData) {
            setIsTutorialDone(
                Boolean(user.userData.is_tutorial_done)
            );
        }
    }, [user]);
    /*********************************************************** */
    useEffect((): any => {
        // every 20 rounds
        const areWeInTenableRoundCount: boolean = (roundsCount % ROUNDS_FREQUENCY_TO_PROMPT_REGISTRATION === 0);
        user && setIsPromptableToAskToRegistar(
            UserService.isStatus(user, "logged-in-as-guest") &&
            areWeInTenableRoundCount && roundsCount !== 0);

        // every 10 steps
    }, [user, roundsCount]);
    /*********************************************************** */
    useEffect(() => {
        lang && user && setStartRound()

    }, [lang]);
    /************************************************************ */
    function setStartRound() {
        dispatchRound({
            type: "null",
            payload: {
                isTutorialDone
            }
        });
        consumeApi(
            "post",
            "round",
            user?.token,
            lang?.codeIso639
        )
            .then((res) => {
                const data = res.data.data;
                setRoundsCount(roundsCount + 1);
                const userData = data.user_data;
                dispatchRound({
                    type: "start",
                    payload: {
                        data,
                        isTutorialDone
                    }
                });
                dispatchUser({
                    type: "setUserDataFromDB",
                    payload: {
                        userData
                    }
                });
            }).catch((err) => {
                setError(err)
            })
    }
    /************************************************************ */
    function changeUserData(userData: UserData | undefined) {
        // console.log("changeUserData()")
        dispatchUser({
            type: "setUserData",
            payload: {
                userData
            }
        });
    }
    /************************************************************ */
    return (
        <div className={"game " + (isTutorialDone ? "" : " game-pristine")} >
            
            {
                !lang
                    ? <LanguageSelector></LanguageSelector>
                    :
                    <>
                        {round && user && user.userData ?
                            <div>
                                <ErrorBoundary
                                    // fallback="Le fallback par défaut"
                                    fallback="App error"
                                    onReset={() => { console.log("ErrorBoundary fallback") }}
                                >
                                    <Round
                                        round={round}
                                        userData={user.userData}
                                        // isFirstRoundKeyboardClicked={isFirstRoundKeyboardClicked}
                                        // firstKeyboardKeyClick={() => { setIsFirstRoundKeyboardClicked(true) }}
                                        setStartRound={setStartRound}
                                        changeUserDataParent={changeUserData}
                                        // setIsTutorialDone={setIsTutorialDone}
                                        setError={setError}
                                        isPromptableToAskToRegistar={isPromptableToAskToRegistar}
                                    ></Round>
                                </ErrorBoundary>
                            </div>
                            :
                            <Loader cssClass="loader-round" />
                        }
                    </>
            }
        </div>
    )
}
export default Game;