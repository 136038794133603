import { useNavigate } from "react-router-dom";
import '../i18n';
import { useTranslation } from "react-i18next";
import cross from '../../image/cross-black.svg';

const PageLayout = ({ children, title, pageName, isCap, hasClosingCross }: any) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <div className={'page page-' + pageName}>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col">

                            {
                                title &&
                                <h1 className="cap" >
                                    {t(title)}
                                </h1>
                            }

                            {
                                hasClosingCross && <div className='float-end'>
                                    <button onClick={() => navigate(-1)} className='btn-icon btn-cross btn-cross-page btn-transparent'>
                                        <img src={cross} className='' alt="lexiar game, round end" />
                                    </button>
                                </div>

                            }

                            {children}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageLayout;