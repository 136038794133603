import Level from '../user-data/Level';
import LevelHighestReached from '../user-data/LevelHighestReached';
import Gem from '../user-data/Gem';
import VictoryPercentage from './VictoryPercentage';
import UserData from '../../entity/UserDataClass';
import Progression from './Progression';

interface Props {
    userData: UserData
}

function UserDataDetails({
    userData: {
        level,
        level_biggest_reached,
        level_step_in_level_percentage,
        victory_percentage,
        rounds_won_count,
        rounds_played_count,
        gems_count
    }
}: Props) {

    return (
        <div className="col" style={{ textAlign: "left" }}>
            <div className='dark-area'>
                <div className="row mt-2 mb-3">
                    {level &&
                        <>
                            <div className="col-12 mb-3">
                                <Level
                                    color={level.color}
                                    label={level.label}
                                    icon={level.icon}
                                    level_step_in_level_percentage={level_step_in_level_percentage}
                                    isShowLabel={true}
                                    iconSize="lg"
                                />
                            </div>

                            <div className="col-12 mb-3">
                                <LevelHighestReached
                                    level={level}
                                    level_biggest_reached={level_biggest_reached}
                                    percentage={100}
                                />
                            </div>
                        </>
                    }
                </div>
                <div className="row">
                    <div className="col-12 col-4 cap">
                        <VictoryPercentage
                            percentage={victory_percentage}
                            // rounds_won_count={rounds_won_count}
                            // rounds_played_count={rounds_played_count}
                            doesShowLabel={false}
                        />
                    </div>
                    <div className="col-12 col-4 cap">
                        <Progression victory_percentage={victory_percentage} />
                    </div>
                    <div className="col-12 col-4 mt-3">
                        <Gem
                            amount={gems_count}
                            isShowLabel={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UserDataDetails;