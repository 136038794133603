import { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from "react-router-dom";
import * as LanguageService from '../service/LanguageService'
import { fetchWordCounts } from '../service/WordService'
import { LanguageContext } from '../context/LanguageContext';
import { ApiContext } from '../context/ApiContext';
import useLanguagePreferedSave from "../hook/useLanguagePreferedSave";
import LanguageClass from '../entity/LanguageClass';

function LanguageSelector() {

    const [wordCounts, setWordCounts] = useState<any>();
    const { user, dispatchUser } = useContext(ApiContext);
    // const { lang } = useContext(LanguageContext);
    const [saveLanguage] = useLanguagePreferedSave();

    // "/" + l.codeIso639 + "/game" 

    const getUrl = useCallback((l:LanguageClass): string => {
        return "/" + l.codeIso639 + "/game";
    }, []);

    const savePreferedLanguage = useCallback((l:LanguageClass): any => {
        user && saveLanguage(user, l.id)
    }, [user]);


    const getCount = (l: string): number =>
        wordCounts ? wordCounts[l] : 0;

    useEffect(() => {
        fetchWordCounts()
            .then((response: any) => {
                try {
                    setWordCounts(response)
                } catch (error) {
                    console.log("err6rt40hdr5tdr1 fetchWordCounts catch error", error)
                }
            })
            .catch((error) => {
                console.log("err544r0t57g0dr475t", error)
            });
    }, []);

    // function savePreferedLanguage(l:LanguageClass) {
    //     user && saveLanguage(user, l.id)
    // }

    return (
        <div className="row language-selector" >
            
            {LanguageService.getLanguages().map((l) =>
                <div
                    key={l.codeIso639}
                    className={`language-selector-lang-${l.codeIso639} col ms-3 mt-1 mb-1 d-flex flex-column justify-content-center align-items-center`}
                >
                    <Link 
                    to={getUrl(l)} 
                    relative="route" 
                    onClick={()=>{savePreferedLanguage(l)}}
                    // onClick={savePreferedLanguage(l)}
                    >
                        <img src={l.image} alt={"Armoirie héraldique " + l.label} />
                    </Link>
                    <Link 
                    to={getUrl(l)} 
                    relative="route" 
                    onClick={()=>{savePreferedLanguage(l)}}
                    className='button-custom pulse mt-1'
                    >
                        {l.prefix} {l.label}
                    </Link>

                    <p className='mt-1 color-bright keep-height-even-if-empty'>
                        {
                            wordCounts &&
                            <>
                                {getCount(l.codeIso639) + " "}
                                {l.t.words}
                            </>
                        }
                    </p>
                </div>
            )}
        </div>
    );
}
export default LanguageSelector;