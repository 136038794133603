import LanguageClass from '../entity/LanguageClass';

export const shuffle = function shuffle(array: any[]): any[] {
    let currentIndex = array.length, randomIndex;
    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    return array;
}

export const getRandomArrayItem = (array: any[]) =>
    array[Math.floor(Math.random() * array.length)];

export function hasArrayDuplicates(arr: any[]): boolean {
    let squashedDuplicates: any = Array.from(
        new Set(
            arr.filter((item: any, index: number) => arr.indexOf(item) !== index)
        )
    );
    return Boolean(squashedDuplicates.length);
}

export function getIntConvertedInString(i: number) {
    var special = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelvth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
    var deca = ['twent', 'thirt', 'fourt', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];
    function stringifyNumber(n: number) {
        if (n < 20) return special[n];
        if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth';
        return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
    }
    return stringifyNumber(i);
}

export function getAmountOfValuesInArray(a: any[], valueToFind: string) {
    let counter = 0;
    let arrValue;
    for (arrValue of a) {
        (arrValue === valueToFind) && (counter++);
    };
    return counter;
}


export function getRuleOfThreePercentage(a: number, b: number) {
    var x: any = (a * 100) / b;
    var n: number = parseInt(x);
    return n;
}

export function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomCharacterFromString(s: any): string {
    let k = Math.floor(Math.random() * s.length);
    // let r: string = s[k];
    return s[k];
}

export const getStatusCssClassName = (isOver: Boolean, isWon: Boolean): string =>
    isOver ? `status status-${isWon?"won":"lost"}-bright-bkg` : "";


export function isEmpty(obj: any) {
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }
    return true;
}

export function isInArrayOfObjects(arrayOfObjects: Array<any>, objectProperty: string, valueToCheck: any): Boolean {
    for (var i = 0; i < arrayOfObjects.length; i++) {
        if (arrayOfObjects[i][objectProperty] === valueToCheck) {
            return true;
        }
    }
    return false;
}

export function getVictoryPercentage(rounds_played_count: number, rounds_won_count: number): number {
    const p: number = getRuleOfThreePercentage(rounds_won_count, rounds_played_count);
    const percentageToDisplay: number = !p || isNaN(p) ? 0 : p;
    return percentageToDisplay;
}

// export const getRelativeUrl = (lang: string | undefined, path: string): string => "/" + lang + "/" + path;
export const getRelativeUrl = (lang: LanguageClass | undefined, path: string): string => "/" + lang?.codeIso639 + "/" + path;

// export const getApiUrl = (url: string | undefined, path: string): string => url + path;

export const getLastOfArray = (arr:any[]): any => arr[arr.length-1];
/*** cookies handling from w3c ************ */

export function setCookie(cname:string, cvalue:string, exdays:number=7):void {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function deleteCookie(cname:string):void {
    // delete by setting past date
    document.cookie = cname + "=; Max-Age=0; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
}

export function getCookie(cname: string) : string|boolean {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false;
}
