import { useTranslation } from "react-i18next";
import { useState, useEffect, useMemo, useLayoutEffect, useReducer, useCallback } from 'react';
// import Modal from 'react-bootstrap/Modal';
// import Button from "./Button";
import Alert from './Alert';

interface Props {
    err: any
}

function ErrorCustom({
    err,
    // err: {
    //     customSlug,
    //     customMessage,
    //     statusText,
    //     message,
    //     customId
    // }
}: Props): JSX.Element {

    const { t } = useTranslation();
    const [msg, setMessage] = useState<string>("");

    let msgShort: string = "";
    let msgLong: string = "";

    function addToMsg(portion: string, onlyForLongMsg = false) {
        (!onlyForLongMsg) && (msgShort += portion);
        msgLong += portion;
    }

    useEffect(() => {
        if (err) {
            // show modal even if closed in the past
            // setIsModalShown(true)
            try {
                addToMsg(t(`error.${err.customSlug}`))
                if (err.customMessage) {
                    addToMsg("\n")
                    addToMsg("server details : ", true)
                    addToMsg(err.customMessage)
                }
            } catch (errorCatched) {
                addToMsg("Error err654reser5r handling error ")
                addToMsg("\n")
                addToMsg(JSON.stringify(errorCatched))
            }
            addToMsg("\n", true)
            addToMsg(err.statusText, true)
            addToMsg("\n", true)
            addToMsg(err.message, true)
            addToMsg("\n", true)
            addToMsg("code : ", true)
            addToMsg(err.customId, true)

            console.error(msgLong);
            setMessage(msg)
        }

    }, [err]);

    return <>
        {err &&
            <Alert message={msg} setMessage={setMessage} />
        }
    </>
};
export default ErrorCustom;