import Language from '../entity/LanguageClass';
import * as UtilService from './UtilService'
import {LANGUAGES} from '../Constants';
import LanguageClass from '../entity/LanguageClass';

const getLanguages = (): Array<Language> => LANGUAGES;


const getLanguageFromId = function (id: number): Language {
    try {
        return getLanguages()[id - 1];
    } catch (e:any) {
        throw new Error("err58e9erg9g " + e);
    }
}

const getIsIsoValid = function (iso: string | undefined): Boolean {
    if (iso) {
        const isValid: Boolean = UtilService.isInArrayOfObjects(getLanguages(), "codeIso639", iso)
        return isValid;
    } else {
        throw new Error("err56r5g6eg84 getIsIsoValid iso empty")
    }
}

const getLanguageFromIso = function (iso: string|undefined): LanguageClass {
    const language:LanguageClass | undefined = 
    getLanguages()
        .find(
            (l)=>{
                return l.codeIso639 === iso;
            }
        )
        // english by default
    return language ? language : getLanguages()[0];
}




export {
    getLanguageFromId,
    getIsIsoValid,
    getLanguages,
    getLanguageFromIso

};