import { useTranslation } from "react-i18next";
import '../i18n';

interface Props {
    error: any
}

function FormInputError({error}:Props) {    
    const { t, i18n } = useTranslation();
    return (
       <>
        {
            error && error.message && 
            <p className='error'>
                {
                    error.message
                }
            </p>
        }
        </>
    );
}
export default FormInputError;