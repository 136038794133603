import { useContext, useEffect } from 'react';
import { ApiContext } from '../../context/ApiContext';
import { LanguageContext } from '../../context/LanguageContext';
import { Link } from "react-router-dom";
import Login from './Login';
import { useTranslation } from "react-i18next";
import PageLayout from '../../layouts/PageLayout';
import ButtonPlay from '../ButtonPlay';
import * as UtilService from '../../service/UtilService';
import UserDataDetails from '../user-data/UserDataDetails';
import * as UserService from '../../service/UserService'
import { useNavigate } from "react-router-dom";
import LanguageClass from '../../entity/LanguageClass';
import * as LanguageService from '../../service/LanguageService'

function Auth() {

    const { user } = useContext(ApiContext);
    const { lang } = useContext(LanguageContext);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        // when logged in, set default user language if not already
        if (
            lang && 
            user && 
            !UserService.isGuest(user) && 
            user.userData && 
            user.userData.language_id !== lang.id
            ) {
            const userLanguage: LanguageClass = LanguageService.getLanguageFromId(user.userData.language_id)
            const url: string = UtilService.getRelativeUrl(userLanguage, "auth");
            navigate(url)
        }
    }, [user]);


    return (
        <PageLayout title={t("auth.account")} pageName="auth" hasClosingCross >
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    {user &&
                        UserService.isStatus(user, "not-logged-in") &&
                        <>
                            <p>
                                {t("auth.register_create")}
                            </p>
                            <Link to={UtilService.getRelativeUrl(lang, "register")} relative="route" className="button-custom">
                                {t("auth.signup")}
                            </Link>
                        </>
                    }
                    {user &&
                        UserService.isStatus(user, "logged-in-as-guest") &&
                        <>
                            <p>
                                {t("auth.upgrade_ask")}
                            </p>
                            <Link to={UtilService.getRelativeUrl(lang, "register/upgrade")} relative="route" className="button-custom">
                                {t("auth.signup")}
                            </Link>
                            <div className='mt-3'>
                                {user.userData &&
                                    <UserDataDetails userData={user.userData} />
                                }
                            </div>
                        </>
                    }
                    {
                        user && UserService.isStatus(user, "logged-in-as-authenticated") &&
                        <>
                            <div className="row">
                                <div className="col">
                                    {t("name")} : {user?.name}
                                    <br />
                                    {t("auth.email")} : {user?.email}
                                </div>
                            </div>
                            <div className="row mt-2">
                                {user.userData &&
                                    <UserDataDetails userData={user.userData} />
                                }
                            </div>
                        </>
                    }
                </div>
                <div className="col-xs-12 col-sm-6 mt-4 mt-sm-0">
                    {user &&
                        UserService.isStatus(user, "not-logged-in") &&
                        <>
                            <Login />
                        </>
                    }
                    {user &&
                        UserService.isStatus(user, "logged-in-as-guest") &&
                        <>
                            <Login />
                        </>
                    }
                    {user &&
                        UserService.isStatus(user, "logged-in-as-authenticated") &&
                        <>
                            <div className="row">
                                <div className="col-6 col-sm-12 mb-sm-5">
                                    <ButtonPlay />
                                </div>
                                <div className="col-6 col-sm-12 mb-sm-5">
                                    <Link to={UtilService.getRelativeUrl(lang, "logout")} relative="route" className="navbar-brand button-custom button-custom-bright" >
                                        {t("auth.logout")}
                                    </Link>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </PageLayout>
    );
}
export default Auth;