
import UserDataClass from "../entity/UserDataClass";
import { useState, useContext } from 'react';
import { ApiContext } from '../context/ApiContext';
import axiosRetry from 'axios-retry';
import axios from 'axios';
import { fetchRoundUpdate } from '../service/RoundService'
import RoundType from "../type/RoundType";

// save round result on DB

const useRoundUpdate = () => {

    const { user } = useContext(ApiContext);
    const [error, setError] = useState<any>();

    function setRoundUpdate(
        id: number,
        isRoundWon: Boolean,
        // lossesCount: number,
        status:RoundType,
        userData: UserDataClass,
        changeUserDataParent: any,
        setIsGemGained: any,
        setIsNewLevelReached: any,
    ) {
        axiosRetry(axios, {
            retries: 3,
            retryDelay: () => 500,
            retryCondition: () => true,
        });
        var fd = new FormData();
        fd.append('is_won_or_lost', isRoundWon ? "1" : "0");
        // isRoundWon && fd.append('is_perfect', getIsPerfect(lossesCount) ? "1" : "0");
        // console.log("status", status)
        isRoundWon && fd.append('is_perfect', status==="perfect" ? "1" : "0");

        fetchRoundUpdate(id, user, fd)
            .then((res) => {
                setIsGemGained(isRoundWon);
                const ud = res.data.user_data;
                // let userDataUpdated: UserData = { ...ud };
                setIsNewLevelReached(ud.level_id > 0 && ud.level_step_in_level_percentage === 0)
                changeUserDataParent({...ud});
            }).catch((err) => {
                setError(err)
            })
    }
    return [setRoundUpdate]
};
export default useRoundUpdate;