import '../../i18n';

interface Props {
    // color?: string,
    percentage?: number,
    emptyColor?: string,
    isEmptyColorTransparent?: boolean,
}

function Gauge({
    // color = "999",
    percentage = 0,
    emptyColor = "999",
    isEmptyColorTransparent = false,
}: Props): JSX.Element {

    function getBkg() {
        let r: any;
        let color: string = isEmptyColorTransparent ? "transparent" : "#"+emptyColor;
        // if (isEmptyColorTransparent) {
        //     color = "transparent";
        // } else {
        //     color = "#" + emptyColor;
        // }
        r = { backgroundColor: color };
        return r;

    }

    return <>
        <div className='gauge' style={getBkg()}>
            <div
                className='gauge-content'
                style={{ width: percentage + "%" }}
            >
            </div>
        </div>
    </>
}
export default Gauge;