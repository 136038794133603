import { Routes, Route, Navigate } from "react-router-dom";
import Auth from './auth/Auth';
import Register from './auth/Register';
import Login from './auth/Login';
import LoginSuccess from './auth/LoginSuccess';
import LoginGuest from './auth/LoginGuest';
import PasswordForgotten from './auth/PasswordForgotten';
import Logout from './auth/Logout';
import Test from './Test';
import Help from './page/Help';
import Level from './page/Level';
import Game from './game/Game';
import Layout from '../layouts/Layout';
import AuthCookieLayout from '../layouts/AuthCookieLayout';
import AuthLayout from '../layouts/AuthLayout';
import AuthNotLayout from '../layouts/AuthNotLayout';
import '../i18n';
import GameLanguageSelector from './page/GameLanguageSelector';
import Credits from './page/Credits';
import Contact from './page/Contact';

function AppRoutes() {

    return (
        <>
            <Routes>

                <Route path="*" element={<Navigate to="en" replace />} />

                <Route element={<Layout />}>

                    <Route path=":lang" >

                        <Route element={<AuthCookieLayout />} >

                            <Route index element={<GameLanguageSelector />} />

                            <Route path="game-language-selector" element={<GameLanguageSelector />} />
                            <Route path="help" element={<Help />} />
                            <Route path="auth" element={<Auth />} />
                            {/* we can registrer when not logged in or logged in a guest     */}
                            <Route path="register" element={<Register status="not-logged-in" />} />

                            <Route path="credits" element={<Credits />} />
                            
                            <Route path="contact" element={<Contact />} />

                            <Route path="levels" element={<Level />} />
                            {/* here logged as anynomous or filledAccount */}

                            {/* <Route path="test" element={<Test />} /> */}

                            {/* needs to be logged in to access */}
                            <Route element={<AuthLayout children={null} mode="route" />} >
                                {/* requires user confirmed or guest loggedin */}
                                <Route path="logout" element={<Logout />} />
                                {/* <Route path="test" element={<Test />} /> */}
                                <Route path="game" element={<Game />} />
                                <Route path="register/upgrade" element={<Register status="logged-in-as-guest" />} />
                            </Route>

                            {/* needs to be logged out to access */}
                            <Route element={<AuthNotLayout children={null} mode="route" />} >
                                <Route path="login" element={<Login />} />
                                <Route path="login-success" element={<LoginSuccess />} />
                                <Route path="login-guest" element={<LoginGuest />} />
                                <Route path="password-forgotten" element={<PasswordForgotten />} />
                            </Route>
                        
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </>
    );
}

export default AppRoutes;