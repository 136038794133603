import { useState, useEffect, useContext } from 'react';
import { ApiContext } from '../../context/ApiContext';
import PageLayout from '../../layouts/PageLayout';
import { Navigate } from "react-router-dom";
import * as UtilService from '../../service/UtilService'
import { LanguageContext } from '../../context/LanguageContext';
import * as UserService from '../../service/UserService'
import { consumeApi } from '../../service/ApiService'
import ErrorCustom from '../ErrorCustom';

function Logout() {
    const { user, dispatchUser } = useContext(ApiContext);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const { lang } = useContext(LanguageContext);
    const [error, setError] = useState<any>();

    useEffect(() => {
        if (user && UserService.isLoggedIn(user)) {
            UtilService.deleteCookie("lexiar_user_token");
            var fd = new FormData();
            (user && fd.append('user_id', user.id));
            consumeApi(
                "post",
                "logout",
                user?.token,
                "",
                {},
                fd
            )
                .then(function (response) {
                    dispatchUser({
                        type: "empty",
                        payload: {
                            response
                        }
                    });
                    setIsLoggedOut(true)
                })
                .catch(function (err) {
                    setError(err)
                });
        }
    });

    return (
        <>
            <ErrorCustom err={error} />
            <PageLayout title="Logged out" pageName="logged_out">
                {
                    isLoggedOut &&
                    <Navigate to={UtilService.getRelativeUrl(lang, "auth")} />
                }
            </PageLayout>
        </>
    );
}
export default Logout;