import { useEffect, useContext, useState } from 'react';
import { Outlet } from "react-router-dom";
import { ApiContext } from '../context/ApiContext';
import * as UtilService from '../service/UtilService'
import { consumeApi } from '../service/ApiService'

const AuthCookieLayout = (): any => {

    const [error, setError] = useState<any>();
    const { dispatchUser } = useContext(ApiContext);

    useEffect(() => {

        const cookieUserToken: string | boolean = UtilService.getCookie("lexiar_user_token");
        cookieUserToken &&
            (cookieUserToken !== "") &&
            getUserFromToken(String(cookieUserToken))


    }, [])


    function getUserFromToken(userToken: string) {
        console.log("getUserFromToken userToken", userToken)
        consumeApi(
            "post",
            "user-from-token",
            userToken,
            ""
        )
            .then(function (apiResponse) {
                dispatchUser({
                    type: "fillWithApiResponse",
                    payload: {
                        apiResponse
                    }
                });
            })
            .catch(function (err) {
                setError(err)
            });
    }
    return <Outlet />
}
export default AuthCookieLayout;