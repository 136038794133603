import UserData from '../../entity/UserDataClass';
import { useState, useRef, useEffect } from 'react';
import Difficulty from './Difficulty';
import RouteHasChanged from '../RouteHasChanged';
import Level from '../user-data/Level';
import Gem from '../user-data/Gem';
import RoundStatusBarHelp from './RoundStatusBarHelp';
import '../../i18n';
import { useTranslation } from "react-i18next";
import VictoryPercentage from '../user-data/VictoryPercentage';

interface Props {
    // dispatchTangerines: any,
    isGemGained: Boolean,
    isNewLevelReached: Boolean,
    difficultyLevel: number,
    userData: UserData
}

// in a round, black top bar below nav to show gems, progress and level

function RoundStatusBar({ 
    // dispatchTangerines,
    isGemGained, 
    isNewLevelReached, 
    difficultyLevel, 
    userData, 
    userData : {
        gems_count,
        victory_percentage,
        rounds_won_count,
        rounds_played_count,
        level,
        level_step_in_level_percentage
    }
}: Props): JSX.Element {

    const [isExpanded, setIsExpanded] = useState(false)
    const wrapperRef = useRef(null);

    useEffect(() => {
        // console.log("RoundStatusBar userData : ",userData)      
    }, []);

    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                // if bar open, close it when click outside
                ref.current && !ref.current.contains(event.target) && setIsExpanded(false);
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    function handleClick(e: any) {
        // bar is toggled expanded or collapsed
        setIsExpanded(!isExpanded);
        e.preventDefault();
        return false;
    }

    // when new level reached, animate it
    const getLevelContainerClassAnimation = (): string =>
        isNewLevelReached ? "pop-level-unique" : "";
    

    return <>

        {/* if the route has changed, collapse the bar */}
        <RouteHasChanged hasChanged={() => { setIsExpanded(false) }} />

        {/* all the bar is clickable to toggle, all inside is only to see, not to click */}
        <a href="#"
            className={'round-status-bar  ' + (isExpanded ? "expanded" : "collapsed")}
            onClick={(e: any) => { handleClick(e); }}
            ref={wrapperRef}
        >

            <header >
                <div className='round-status-bar__difficulty'>
                    <Difficulty levelId={difficultyLevel} isLabelShown={true}></Difficulty>
                </div>
                <div className='round-status-bar__gems'>
                    <Gem
                        amount={gems_count}
                        isGemGained={isGemGained}
                        isShowLabel={false}
                    />
                </div>
                <div className='round-status-bar__victory-percentage cap'>

                    <VictoryPercentage
                        percentage={victory_percentage}
                        // rounds_won_count={rounds_won_count}
                        // rounds_played_count={rounds_played_count}
                        doesShowDetails={false}
                    />
                </div>
                <div className={" level-container " + getLevelContainerClassAnimation()}>
                    <Level
                        color={level.color}
                        label={level.label}
                        icon={level.icon}
                        level_step_in_level_percentage={level_step_in_level_percentage}
                    />

                </div>
            </header>

            <div className='round-status-bar-content pt-1 pb-2' >
                {/* Visible part when bar expanded */}
                <RoundStatusBarHelp userData={userData} />
            </div>

        </a>
    </>
}
export default RoundStatusBar;