// import { createContext } from 'react';

// Monti
// export const domain: string = "192.168.1.119";
// export const domain: string = "192.168.1.107";
// export const domain: string = "192.168.1.255";
// export const domain: string = "192.168.0.143";

// Android hot spot
// export const domain: string = "192.168.43.196";

// Thonon
// export const domain: string = "192.168.1.20";

export const BACKEND_URL: string = process.env.REACT_APP_BACKEND_URL as string
export const BACKEND_API_URL: string = BACKEND_URL + "/api/"
export const BACKEND_IMAGES_URL: string = process.env.REACT_APP_BACKEND_IMAGES_URL as string
export const FRONTEND_URL: string = process.env.REACT_APP_FRONTEND_URL as string

// export const imagesUrlBase: string | undefined = process.env.REACT_APP_DOMAIN
// export const serverPort: string | undefined = process.env.REACT_APP_SERVER_PORT

// export const serverUrl: string = protocolAndDomain + ":8000";
// export const serverUrl: string = serverPort ?
//     protocolAndDomain + ":" + serverPort :
//     protocolAndDomain as string;

// export const frontUrl: string = protocolAndDomain + ":3000";

// export const param: any = {
//     baseServerUrl: serverUrl + "/",
//     api: {
//         url: serverUrl + "/api/"
//     }
// }


export function getApiUrl(suffix: string = "", lang: string = ""): string {
    let url: string = "";
    // url += param.api.url;
    // url += BACKEND_URL + "/api/";
    // console.log("getApiUrl()...", "BACKEND_URL", BACKEND_URL )
    url += BACKEND_API_URL;
    // url += lang ? lang+"/" : "";
    url += lang ? `${lang}/` : "";
    url += suffix;
    return url;
}

// export const ConfigContext = createContext(param);