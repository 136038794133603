import { useEffect } from 'react';
import { useParams, Outlet } from "react-router-dom";
import Header from "../component/Navbar";
import { LanguageContext } from '../context/LanguageContext';
import * as LanguageService from '../service/LanguageService'
import { useTranslation } from "react-i18next";
import LanguageClass from '../entity/LanguageClass';

const Layout = () => {
    const { i18n } = useTranslation();
    let params = useParams();
    let languageIso:string|undefined = params.lang;
    let lang:LanguageClass = LanguageService.getLanguageFromIso(languageIso);

    useEffect(() => {
        lang && i18n.changeLanguage(lang.codeIso639);
    }, [lang, i18n]);

    return (
        <>
            <LanguageContext.Provider value={{ lang }}>
                <header>
                    <Header />
                </header>
                <main>
                    <Outlet />
                </main>
            </LanguageContext.Provider>
        </>
    );
};

export default Layout;