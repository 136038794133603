import { useTranslation } from "react-i18next";

interface Props {
    victory_percentage: number
}

function Progression({ victory_percentage }: Props) {

    const { t } = useTranslation();
    const isProgressing = (): boolean => victory_percentage >= 50;

    return (
        <div className="cap">
            {isProgressing() ?
                <span className='cap status status-won status-won-bright'>
                    {t("game.progressing")} ➚
                </span>
                :
                <span>
                    {t("game.regressing")}  ➘
                </span>
            }
            {" "}
            {t("to")}
            {" "}
            {isProgressing() ? t("next") : t("previous")}
            {" "}
            {t("level.level", { count: 1 })}
            {" "}
            {t("soon")}
            {" "}
        </div>
    );
}
export default Progression;