import '../../i18n';
import { useTranslation } from "react-i18next";

interface Props {
    icon: string | undefined,
    size?: string
}

function LevelIcon({
    icon,
    size = "xs",
}: Props): JSX.Element {

    const { t } = useTranslation();

    const getIconFontSize = (): number =>
        ((size === "xs") && 16) ||
        ((size === "sm") && 20) ||
        ((size === "md") && 26) ||
        ((size === "lg") && 32) ||
        ((size === "xl") && 60) ||
        26;

    return <>
        <span className='level-icon' style={{ fontSize: getIconFontSize() }}>
            {icon && t(icon)}
        </span>
    </>
}
export default LevelIcon;