import '../../i18n';
import { useTranslation } from "react-i18next";
import LevelIcon from '../user-data/LevelIcon';
import Gauge from '../user-data/Gauge';

interface Props {
    label: string | undefined,
    icon: string | undefined
    color: string | undefined,
    level_step_in_level_percentage?: number | undefined,
    isShowLabel?: boolean,
    isIconFirst?: boolean,
    iconSize?: string,
    hasDropShadow?: boolean,
    isBlured?: boolean,
}

function Level({
    label,
    icon,
    color,
    level_step_in_level_percentage,
    isShowLabel = false,
    isIconFirst = false,
    iconSize = "xs",
    hasDropShadow = false,
    isBlured = false,
}: Props): JSX.Element {

    const { t } = useTranslation();

    const jsxText: any = <span className='level-label'>
        {isShowLabel &&
            <span className='cap'>
                {t("level.level", { count: 1 })} {" "}
            </span>
        }
        {" "}
        <span style={{ color: "#" + color }} className='cap'>
            <b className='cap'>
                {" "}
                {t("level." + label)}
            </b>
        </span>
        {" "}
    </span>;

    const jsxIcon: any = <LevelIcon icon={icon} size={iconSize} />

    return <>
        <span
            className={
                "level-icon-and-label "
                // + " d-flex "
                + (hasDropShadow ? " text-shadow-md" : "")
                + (isBlured ? " blured" : "")
            }
        >
            {isIconFirst ?
                <>
                    {jsxIcon}
                    {jsxText}
                </>
                :
                <>
                    {jsxText}
                    {jsxIcon}
                </>
            }
        </span>

        {level_step_in_level_percentage !== undefined &&
            <Gauge percentage={level_step_in_level_percentage} />
        }
    </>
}
export default Level;