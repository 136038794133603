class ApiErrorClass {

    // public test: number;

    public message: string;
    public status: number;
    public statusText: string;
    public customId: string;
    public customSlug: string;
    public customMessage: string;

    public constructor(

        message: string,
        status: number,
        statusText: string,
        customId: string = "",
        customSlug: string = "",
        customMessage: string = ""

    ) {
        
        this.message = message
        this.status = status
        this.statusText = statusText
        this.customId = customId
        this.customSlug = customSlug
        this.customMessage = customMessage

    }
}
export default ApiErrorClass;