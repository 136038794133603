import { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import { useTranslation } from "react-i18next";
import screenshot_BEAR_begining from '../../image/manual/screenshot-BEAR-begining.jpg';
import screenshot_BEAR_keyboard from '../../image/manual/screenshot-BEAR-keyboard.jpg';
import screenshot_BEAR_image from '../../image/manual/screenshot-BEAR-image.jpg';
import screenshot_BEAR_tile from '../../image/manual/screenshot-BEAR-tile.jpg';
import screenshot_BEAR_end_en from '../../image/manual/screenshot-BEAR-end-en.jpg';
import screenshot_BEAR_end_fr from '../../image/manual/screenshot-BEAR-end-fr.jpg';
import screenshot_BEAR_end_es from '../../image/manual/screenshot-BEAR-end-es.jpg';

function Manual() {

    const { lang } = useContext(LanguageContext);
    const { t } = useTranslation();

    return (

        <div className='p-3 bkg-color-brighter rounded color-dark'>
            <div className="row">
                <div className="col-6 col-sm-4">
                    <img src={screenshot_BEAR_begining}
                        className='img-thumbnail mb-3'
                        alt='An example of Lexiar gameplay with the word BEAR'
                    />

                    <img src={

                        (lang?.codeIso639 === "en" ? screenshot_BEAR_end_en : undefined)
                            ||
                            (lang?.codeIso639 === "fr" ? screenshot_BEAR_end_fr : undefined)
                            ||
                            (lang?.codeIso639 === "es" ? screenshot_BEAR_end_es : undefined)

                    } className='img-thumbnail mb-3' alt="lexiar game, round end" />

                </div>
                <div className="col-6 col-sm-8">
                    <h2 className='cap'>
                        {t("manual")}
                    </h2>
                    <p>
                        {t("manual_bear_word")}
                    </p>
                    <p>
                        {t("manual_bear_word_keyboard")}
                        <br />
                        <img src={screenshot_BEAR_keyboard} style={{ width: 100, border: "1px solid white" }} />
                    </p>
                    <p>
                        {t("manual_bear_word_image")}
                        <br />
                        <img src={screenshot_BEAR_image} style={{ width: 70, border: "1px solid white" }} />
                    </p>
                    <p>
                        {t("manual_bear_word_tile")}
                        <br />
                        <img src={screenshot_BEAR_tile} style={{ width: 70, border: "1px solid white" }} />
                    </p>

                </div>
            </div>
        </div>

    );
}

export default Manual;