import { ReactNode } from 'react';

interface WrapperProps {
    isClickable: boolean,
    children: ReactNode,
    handleClick: any
}

function ConditionalLinkWrapper({ isClickable, children, handleClick }: WrapperProps): JSX.Element {

    const getKeyClickableOrNot = (children: ReactNode) =>
        <a href="#" onClick={(e: any) => { handleClick(e, children); }} > {children} </a>


    return <>
        {isClickable ? getKeyClickableOrNot(children) : children}
    </>

}

export default ConditionalLinkWrapper;