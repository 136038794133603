import UserData from '../../entity/UserDataClass';
import UserDataDetails from '../user-data/UserDataDetails';
import HelpDifficultyGems from '../HelpDifficultyGems';
import cross from '../../../image/cross-white.svg';

interface Props {
    userData: UserData
}

function RoundStatusBarHelp({ userData }: Props): JSX.Element {

    return <div >
        <div className="row ">
            <div
                className='col-12 col-sm-12 btn-icon btn-cross btn-cross-page my-2'
                style={{
                    display: "flex",
                    justifyContent: "end",
                    opacity: .5,
                    paddingRight: "20px",
                    width: "100%"
                }}
            >
                <img src={cross} className='' alt="close status bar" />
            </div>
            <div className="row m-0">
                <div className="col-12 col-sm-6">
                    {userData &&
                        <UserDataDetails userData={userData} />
                    }
                </div>
                <div className="col-12 col-sm-6 mt-2 mt-sm-0">
                    <HelpDifficultyGems />
                </div>
            </div>
        </div>
    </div>
}
export default RoundStatusBarHelp;