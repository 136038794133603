import RoundType from '../type/RoundType';
import LetterClass from './LetterClass';
import Word from './WordClass';
import * as UtilService from '../service/UtilService'

class RoundClass {

    public id: number;
    public status: RoundType;
    public word: Word;
    public letters: LetterClass[] = [];

    public constructor(
        id: number,
        word: Word,
        isFirstRoundKeyboardClicked: Boolean = false,

    ) {

        this.id = id;
        this.word = word;
        this.status = "pristine";
        const randomLetterIndex: number = UtilService.getRandomInt(0, word.name.length - 1);

        word.name.split("").forEach((l:string)=>{
            this.letters.push(new LetterClass(l))
        })

        // board init with first tile unveil OR board init with random tile unveil
        this.letters[ 
            (!isFirstRoundKeyboardClicked) ? 0 : randomLetterIndex 
        ].status = "lost"

    }
}
export default RoundClass;