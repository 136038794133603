import { useContext, ReactNode } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { ApiContext } from '../context/ApiContext';
import * as US from '../service/UserService'

interface GameProps {
    children: ReactNode | null;
    mode: string | null;
}

const AuthNotLayout = ({ children = null, mode = "route" }: GameProps): any => {

    const { user } = useContext(ApiContext);

    return user && (
        (mode === "route" && (!US.isLoggedIn(user) ? <Outlet /> : <Navigate to="game" />)) ||
        (mode === "link" && (!US.isLoggedIn(user) ? children : null))
    )
}

export default AuthNotLayout;