import  { AxiosResponse } from 'axios';
import { consumeApi } from './ApiService'

export const fetchWordCounts = async (): Promise<any> => {
    // console.log("WordService fetchWordCounts()")
    const response: AxiosResponse<any> = await consumeApi(
        "get",
        "word-counts-per-language"
    )
    return response.data;
};