import { useTranslation } from "react-i18next";

interface Props {
    amount: number | string | undefined;
    isShowLabel: Boolean;
    isGemGained?: Boolean;
    isInline?: boolean;
    isBkgBright?: boolean;
}

function Gem({ 
    amount, 
    isShowLabel, 
    isGemGained = false, 
    isInline = false ,
    isBkgBright = false 
}: Props): JSX.Element {

    const { t } = useTranslation();

    const getMoneyContainerClassAnimation = (): string => isGemGained ? "pop-gem-unique" : "";
    
    const getStyle = (): any => { return { display: "inline" }};

    return <>
        <span style={isInline ? getStyle() : undefined}>
            <div className={"money " + (isBkgBright ? " money--bkgBright" : "" )  } >
                {isShowLabel &&
                    <span className='money-title cap-margless cap'>
                        {t("game.gem", { count: 2 })}
                    </span>
                }
                <div className={" money-container " + getMoneyContainerClassAnimation()}>
                    <div className="money-shape">
                        <div className="money-text">
                            {amount}
                        </div>
                    </div>
                </div>
                <span>
                    {isShowLabel &&
                        <span className=''>
                            {t("game.gem_to_spend")}.
                        </span>
                    }
                </span>
            </div>
        </span>
    </ >
}
export default Gem;