import { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import '../i18n';
import { LanguageContext } from '../context/LanguageContext';
import * as LanguageService from '../service/LanguageService'
import * as UserService from '../service/UserService'
import { useLocation } from 'react-router-dom'
import { consumeApi } from '../service/ApiService'
import { ApiContext } from '../context/ApiContext';
import useLanguagePreferedSave from "../hook/useLanguagePreferedSave";

function LanguageSelectorSmall() {
    const { lang } = useContext(LanguageContext);
    const { user, dispatchUser } = useContext(ApiContext);
    const [error, setError] = useState<any>();
    const [saveLanguage] = useLanguagePreferedSave();

    const location = useLocation();

    function getCurrentUrlWithLang(l: string): string {
        // from /en/xxx to /fr/xxx
        const path = location.pathname;
        const segments = path.split("/");
        // maybe fr or /fr so gotta get proper key :
        const langCurrentKey: number = segments[0] ? 0 : 1
        const langCurrent: string = segments[langCurrentKey];
        const isIsoValid: Boolean = LanguageService.getIsIsoValid(langCurrent);
        if (!isIsoValid) throw "errr48tg64rtg langCurrent is not valid : " + langCurrent;
        segments[langCurrentKey] = l;
        const url: string = segments.join("/");
        return url;
    }

    

    return (
        <div className='language-links'>
            {LanguageService.getLanguages().map((l) =>
                (lang?.codeIso639 === l.codeIso639) ?
                    <div key={l.codeIso639} className={l.codeIso639}>
                        {l.codeIso639}
                    </div>
                    :
                    <Link
                        to={getCurrentUrlWithLang(l.codeIso639)}
                        onClick={() => {
                            user && saveLanguage(user, l.id)
                        }}
                        relative="route"
                        key={l.codeIso639}
                        className={l.codeIso639}
                    >
                        {l.codeIso639}
                    </Link>
            )}
        </div>
    );
}
export default LanguageSelectorSmall;