import '../../i18n';
import { useTranslation } from "react-i18next";
import i1 from '../../../image/difficulty/difficulty-icons_1.svg';
import i2 from '../../../image/difficulty/difficulty-icons_2.svg';
import i3 from '../../../image/difficulty/difficulty-icons_3.svg';
import i4 from '../../../image/difficulty/difficulty-icons_4.svg';

interface TileProps {
    levelId: number;
    isLabelShown?:Boolean;
    isTextualMeaningShown?:Boolean;
}
function Difficulty({ 
    levelId, 
    isLabelShown=false,
    isTextualMeaningShown=false
 }: TileProps): JSX.Element {
    const { t, i18n } = useTranslation();

    const getIcon = (): any => [i1,i2,i3,i4][levelId - 1];
    
    return <span className='cap'>
        
        {isLabelShown && 
            t("difficulty.difficulty",{count:1})
        }
        {isTextualMeaningShown && 
            <>
            {t("difficulty.difficulty_id_" + levelId)}
            <br />
            </>
        }
        <span className={`icon-difficulty icon-difficulty-level-${levelId} m-1`}>
            <img src={getIcon()} alt={`Difficulty ${levelId}/4`} />
        </span>
    </span>
}
export default Difficulty;