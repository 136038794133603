import { useTranslation } from "react-i18next";
// import { useState, useEffect, useMemo, useLayoutEffect, useReducer, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from "./Button";

interface Props {
    message: string,
    setMessage: any
}

function Alert({
    message,
    setMessage
}: Props): JSX.Element {

    const { t } = useTranslation();
    // const [message, setMessage] = useState<string>();
    // const [isModalShown, setIsModalShown] = useState<boolean>(true);

    return <>
        {message &&
            <Modal show={!!message} onHide={() => { setMessage("") }}>
                <Modal.Header closeButton>
                    <Modal.Title className="cap">
                        {t("error.error")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: "flex" }}>
                    {message}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setMessage("") }}>
                        ok
                    </Button>
                </Modal.Footer>
            </Modal>
        }
    </>
};
export default Alert;