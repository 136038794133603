import { useTranslation } from "react-i18next";
import Difficulty from './game/Difficulty';
import './../i18n';
import Gem from './user-data/Gem';
import screenshot_BEAR_tile from '../../image/manual/screenshot-BEAR-tile.jpg';

function HelpDifficultyGems() {

    const { t } = useTranslation();

    return (
        <div className='dark-area'>
            {t("game.higher_levels_give_more_difficult_words")}
            <Difficulty levelId={1} ></Difficulty>
            <hr />
            {t("game.more_difficult_words")}
            <Difficulty levelId={1} ></Difficulty>
            {t("game.gives_more")}
            <Gem
                amount={t("game.gem", { count: 2 })}
                isGemGained={false}
                isShowLabel={false}
                isInline={true} />
            <hr />
            {t("game.you_can_spend")}
            {" "}
            {t("game.gem", { count: 2 })}
            {" "}
            {t("game.unveiling_a_tile")}
            {" "}
            <img src={screenshot_BEAR_tile} style={{ width: 60, border: "1px solid white" }} />
        </div>
    );
}
export default HelpDifficultyGems;