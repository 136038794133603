import RoundVictoryOrLostStatus from './RoundVictoryOrLostStatus';
import * as UtilService from '../../service/UtilService'
import { useTranslation } from "react-i18next";

interface Props {
    isRoundOver: Boolean,
    isRoundWon: Boolean,
    handleClick: any,
    isPerfect: Boolean
}

function RoundOverStatusAndButton({ isRoundOver, isRoundWon, handleClick, isPerfect }: Props): JSX.Element {

    const { t } = useTranslation();

    return <div
        className={
            `round-over-status-and-button ${UtilService.getStatusCssClassName(isRoundOver, isRoundWon)}`
        }
    >
        <RoundVictoryOrLostStatus isWon={isRoundWon} isPerfect={isPerfect}></RoundVictoryOrLostStatus>
        <div>
            <button onClick={handleClick} className='button-custom pulse'>
                {t("game.next")}
            </button>
        </div>
    </div>
}
export default RoundOverStatusAndButton;