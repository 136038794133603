import '../../i18n';
import { useTranslation } from "react-i18next";
import Level from './Level';

interface Props {
    level: any,
    level_biggest_reached: any,
    percentage: number
}

function LevelHighestReached({
    level,
    level_biggest_reached,
    percentage
}: Props): JSX.Element {

    const { t, i18n } = useTranslation();

    const isHigherThanCurrent = (): boolean =>
        level.label !== level_biggest_reached.label;

    return <>
        {isHigherThanCurrent() &&
            <>
                <span>
                    {t("level.level_highest_reached")} {" "}
                </span>
                <Level
                    color={level_biggest_reached.color}
                    label={level_biggest_reached.label}
                    icon={level_biggest_reached.icon}
                    level_step_in_level_percentage={percentage}
                    iconSize="lg"
                />
            </>
        }
    </>
}
export default LevelHighestReached;