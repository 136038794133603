import LetterClass from '../entity/LetterClass';
import * as RoundService from '../service/RoundService'
import RoundType from '../type/RoundType';

const useRoundKeyboardKeyClick = () => {

    function setRoundKeyboardKeyClick(
        {keyboardKey}: any,
        roundStatus: RoundType,
        letters: LetterClass[],
        lossesCount: number,
        setLossesCount: any,
        setLetters: any,
        setRoundStatus: any,
        // firstKeyboardKeyClick: any
    ) {

        // to disable keybaord tuto
        // firstKeyboardKeyClick()
        var indexes: Array<number> = [];

        letters.forEach((l: LetterClass, i: number) => {
            (l.content === keyboardKey.content) &&
                // maybe word contains the won letter several time (ABBA)
                indexes.push(i);
        })

        const getIsLetterWon = (): boolean => !!indexes.length;

        if (getIsLetterWon()) {
            indexes.map((index) => (
                letters[index].status = "won"
            ));
            // if letter clicked in word, set proper letter(s) won and return letters
        } else {
            // if letter clicked not in word, show an image on a random pristine tile
            const randomPristineLetterKey = RoundService.getOneRandomPristineLetterKey([...letters]);
            // when loosing key, if letters are all won or lost
            randomPristineLetterKey === -1 ?
                // lost, because only left won and lost letters
                (roundStatus = "lost") :
                (letters[randomPristineLetterKey].status = "lost") 
                // &&
                //     (letters = letters));
        }
        // if round not over, check if all tiles won or lost, so we can set round over  (won or lost)
        roundStatus === "pristine" &&
            (roundStatus = RoundService.getStatusIfAllLettersAre(letters, lossesCount));

        // 0 loss means perfect round
        !getIsLetterWon() && setLossesCount((count: number) => { return count + 1 });
        // set letters back to round
        setLetters(letters);
        // set round status, maybe it won t change
        setRoundStatus(roundStatus);
    }

    return [setRoundKeyboardKeyClick]
};
export default useRoundKeyboardKeyClick;