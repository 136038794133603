import LanguageClass from './LanguageClass';

class WordClass {
    public constructor(
        id: number,
        name: string,
        isInternationalCulture: boolean,
        isProperNoun: boolean,
        idDifficulty: number,
        language: LanguageClass
        ) {
        this.id = id;
        this.name = name;
        this.isInternationalCulture = isInternationalCulture;
        this.isProperNoun = isProperNoun;
        this.idDifficulty = idDifficulty;
        this.language = language;
    }
    public id: number;
    public name: string;
    public isInternationalCulture: boolean;
    public isProperNoun: boolean;
    public idDifficulty: number;
    public language: LanguageClass;
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getAmountOfLetters() {
        return this.getName().length
    }
}

export default WordClass;