import TileType from '../type/TileType';

class KeyboardKeyClass {
    
    public content;
    public status:TileType = "pristine";
    public isPressed = false;

    constructor(content:any, isPressed = false) {
      this.content = content;
    }
  }

  export default KeyboardKeyClass;