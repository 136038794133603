import { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import PageLayout from '../../layouts/PageLayout';
import '../../i18n';
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import Captcha from '../auth/Captcha';
import { ApiContext } from '../../context/ApiContext';
import { Navigate, redirect } from "react-router-dom";
import * as UtilService from '../../service/UtilService'
import Loader from '../Loader';
import FormInputError from '../FormInputError';
import { consumeApi } from '../../service/ApiService'
import Modal from 'react-bootstrap/Modal';
import Button from "../Button";
import FormType from '../../type/FormType';
import img_share from '../../../image/navigation-icon/share.png';

import {
    EMAIL_VALID,
    MESSAGE_VALID
} from '../../Constants';

function Help() {

    const { lang } = useContext(LanguageContext);
    const { t, i18n } = useTranslation();
    const { register, watch, getValues, handleSubmit, formState: { errors, isValid } } = useForm({ mode: "onChange" });
    const { user, dispatchUser } = useContext(ApiContext);
    const [formState, setFormState] = useState<FormType>("pristine");
    const [captchaToken, setCaptchaToken] = useState<string>("");
    const [error, setError] = useState<any>();

    // ***********************************

    const maxChar: number = useMemo(() => { return 512 }, []);

    // ***********************************

    const setShareAlt = async () => {
        
            try {
                await navigator.share(
                    {
                        title: "MDN",
                        text: "Learn web development on MDN!",
                        url: "https://developer.mozilla.org",
                    }
                );
                //   resultPara.textContent = "MDN shared successfully";
            } catch (err) {
                //   resultPara.textContent = `Error: ${err}`;
                console.error("err6d5fxtr6t5grrt6 native share")
            }
        
    }
    const setShare = async () => {
        // console.log("setShare")
        // const shareData = {
        //     title: "MDN",
        //     text: "Learn web development on MDN!",
        //     url: "https://developer.mozilla.org",
        // };


        // if (navigator.share) {
        navigator.share({
            title: "MDN",
            text: "Learn web development on MDN!",
            url: "https://developer.mozilla.org",
        })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        // } else {
        //     console.error("nav share NOT")
        // }


        //   const btn = document.querySelector("button");
        //   const resultPara = document.querySelector(".result");

        // Share must be triggered by "user activation"
        //   btn.addEventListener("click", async () => {
        // try {
        // await navigator.share(shareData);
        //   resultPara.textContent = "MDN shared successfully";
        // } catch (err) {
        //   resultPara.textContent = `Error: ${err}`;
        // setError("Sharing impossible")
        // }
        //   });

    }

    // ***********************************

    const onCaptchaChange = (v: string) => {
        console.log(v)
        setCaptchaToken(v);
    };
    // ***********************************

    function setContact(
        email = EMAIL_VALID,
        message = MESSAGE_VALID,
    ) {
        setFormState("submitted")
        var fd = new FormData();
        fd.append('email', email);
        fd.append('message', message);
        fd.append('captchaToken', captchaToken);

        consumeApi(
            "post",
            "contact",
            "",
            "",
            {},
            fd
        )
            .then(function (apiResponse) {
                console.warn("OPEN MODAL TO SAY MESSAGE NOT SENT OR SENT", apiResponse)
                // isSubmitted
                setFormState(apiResponse.data.success ? "successful" : "unsuccessful")
                // return redirect(UtilService.getRelativeUrl(lang, "contact"));
            })
            .catch(function (err) {
                setError(err)
            });
    }

    // ***********************************

    const onSubmit = (data: any) => {
        // console.log("SUBMT")
        setContact(data.email, data.message);
    };
    // ***********************************

    return (
        <PageLayout title={t("contact")} isCap={true} pageName="contact" hasClosingCross>


            <div className="row">
                <div className="col col-md-12 ">
                    <form onSubmit={handleSubmit(onSubmit)} noValidate >

                        {/* *** email ******************************************************** */}
                        <fieldset className='row mb-1'>
                            <label className='col-3' htmlFor="email">
                                {t("auth.email")}
                            </label>
                            <div className="col-9">
                                <input
                                    type="email"
                                    id="email"
                                    placeholder={t("auth.placeholder_email")}
                                    {...register("email",
                                        {
                                            required: {
                                                value: true,
                                                message: t("auth.email") + " " + t("required")
                                            },
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: t("auth.email") + " " + t("must_be_valid")
                                            },
                                            minLength: {
                                                value: 7,
                                                message: "7 " + t("characters_minimum")
                                            },
                                            maxLength: {
                                                value: 64,
                                                message: "64 " + t("characters_maximum")
                                            }
                                        })}
                                    required
                                />
                                <FormInputError error={errors.email} />
                            </div>
                        </fieldset>

                        {/* *** pass ******************************************************** */}

                        <fieldset className='row mb-1'>
                            <label className='col-3' htmlFor="message">
                                {t("message")}
                            </label>
                            <div className="col-9">
                                <textarea
                                    id="message"
                                    placeholder={t("message")}
                                    maxLength={maxChar}
                                    rows={5}
                                    style={{ width: "100%" }}
                                    {...register("message",
                                        {
                                            required: {
                                                value: true,
                                                message: t("message") + " " + t("required")
                                            },
                                            maxLength: {
                                                value: maxChar,
                                                message: maxChar + " " + t("characters_maximum")
                                            }
                                        })}
                                    required
                                />
                                <FormInputError error={errors.message} />
                            </div>
                        </fieldset>
                        {/* *********************************************************** */}
                        <div className="row mt-3">
                            <div className="col">
                                <button
                                    type="submit"
                                    className='button-custom'
                                    disabled={!isValid}
                                >
                                    {t("auth.signup")}
                                </button>
                            </div>
                        </div>
                        {/* *********************************************************** */}
                        {
                            formState === "submitted" &&
                            <Loader cssClass="loader-sm" />
                        }
                        {
                            <Modal
                                show={formState === "successful" || formState === "unsuccessful"}
                                onHide={() => { setFormState("pristine") }}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title className="cap">
                                        {
                                            formState === "successful" ?
                                                "Sent" :
                                                t("error.error")
                                        }
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ display: "flex" }}>
                                    {
                                        formState === "successful" ?
                                            "Your e-mail has been successfully sent" :
                                            t("error.error")
                                    }
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => { setFormState("pristine") }}>
                                        ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        }
                    </form>
                    <div className="row">
                        <div className="col mt-2">
                            <Captcha onChange={onCaptchaChange} />
                        </div>
                    </div>
                </div>
                {/* *********************************************************** */}
                <div className="col ">
                    {/* Share Share Share */}
                    <Button onClick={() => {
                        setShare()
                        setShareAlt();
                        
                    }}>
                        <span className='navigation-icon ' >
                            <img src={img_share} />
                        </span>
                        <span className='cap'>
                            Share
                        </span>
                    </Button>
                </div>
            </div>


        </PageLayout>
    );
}

export default Help;