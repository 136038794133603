import loader from '../../image/loader.gif';

interface Props {
    cssClass?: string
}

function Loader({ cssClass = "" }) {
    return (
        <div className={"loader " + cssClass}>
            <img src={loader} alt='Please wait, loading...' />
        </div>
    );
}
export default Loader;