
import { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import '../i18n';
import { useTranslation } from "react-i18next";
import Button from "./Button";
interface Props {
    isBright?: Boolean
}

function ButtonPlay({ isBright = false }: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const { lang } = useContext(LanguageContext);
    // const { t, i18n } = useTranslation();


    return <Button path="game" isPulsing isBright={isBright} >
        {t("game.play")}
    </Button>
}
export default ButtonPlay;