import { useState, useEffect, useMemo, useLayoutEffect, useReducer, useCallback } from 'react';
import AppRoutes from './ts/component/AppRoutes';
import { ApiContext } from './ts/context/ApiContext';
import User from './ts/entity/UserClass';
import './ts/i18n';
import { useTranslation } from "react-i18next";
import mobilePortraitLandscape from './image/mobile-portrait-landscape.gif';
import * as UserService from './ts/service/UserService'
import { BrowserRouter, useNavigate } from "react-router-dom";
import CookieConsentContainer from './ts/component/CookieConsentContainer';

function App() {
    const [user, dispatchUser] = useReducer(
        UserService.reduceUser,
        new User()
    )
    const [size, setSize] = useState([0, 0]);
    const url: string = useMemo(() => { return "" }, []);
    const { t } = useTranslation();
    const [width, height] = useWindowSize();

    useEffect(() => {
        console.log("_________________________ user ", user)

        // console.log("process.env.NODE_ENV", process.env.NODE_ENV, "REACT_APP_TEST", process.env.REACT_APP_TEST)
        // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        //     // dev code
        // } else {
        //     // production code
        // }

    }, [user]);

    function useWindowSize() {
        useLayoutEffect(() => {
            const updateSize = () => {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    const getWidthHeightRatio = useCallback((): number => {
        let r: number = width / height;
        r *= 10;
        r = Math.round(r);
        // square returns 10
        return r;
    }, [width, height]);

    const isTooSmallAndLandscapedToDisplay = useCallback((): boolean => {
        const r = getWidthHeightRatio();
        // if small mobile phone on landscape
        return height < 450 || r > 18
    }, [height]);

    return (
        <>

            {/* {isTooSmallAndLandscapedToDisplay() &&
                <div className='warning-mobile-no-landscape-but-portrait'>
                    <p>
                        {t("warning_mobile_no_landscape_but_portrait")}
                    </p>
                    <img src={mobilePortraitLandscape} alt="Icon of phone to be rotated" />
                </div>
            } */}

            <div className={"app auth-" + UserService.getStatus(user) + (isTooSmallAndLandscapedToDisplay())}>
                <ApiContext.Provider value={{ url, user, dispatchUser }}>
                    <BrowserRouter basename="/">
                        <AppRoutes></AppRoutes>
                    </BrowserRouter>
                </ApiContext.Provider>
            </div>

            <CookieConsentContainer />
        </>
    );
}

export default App;