import * as UtilService from '../../service/UtilService'
import { useState, useEffect, useContext, useMemo, useCallback, ReactElement } from 'react';
import Loader from '../Loader';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { LanguageContext } from '../../context/LanguageContext';
import cross from '../../../image/cross-black.svg';
import fullscreenWhite from '../../../image/fullscreen-white.svg';

interface Props {
    wordName: string,
    isRoundOver: Boolean,
    isRoundWon: Boolean
}

function Wikipedia({ wordName, isRoundOver, isRoundWon }: Props) {
    const [isZoomed, setIsZoomed] = useState<Boolean>(false);
    const [suggestions, setSuggestions] = useState<Object[]>();
    const [extracts, setExtracts] = useState<any>();
    const { lang } = useContext(LanguageContext);

    useEffect(() => {
        (isRoundOver) &&
            getWikipediSuggestions()

    }, [isRoundOver, isRoundWon]);

    const getWikipediaCollapseButton = useCallback((): ReactElement => {
        return isZoomed ?
            <button onClick={handleZoomClick} className="btn-icon btn-cross btn-cross-page btn-transparent button-cross">
                <img src={cross} className='' alt="lexiar game, round end" />
            </button>
            :
            <button onClick={handleZoomClick} className="button-custom btn-icon btn-fullscreen btn-cross-page button-cross">
                <img src={fullscreenWhite} alt={"Larger wikipedia description of word : " + wordName} />
            </button>
    }, [isZoomed]);

    const getExtractsToDisplay = useCallback(() => {
        const content: any = extracts.query.pages
        // get first property of page, it s an id we don t know, ex: 118451
        const page: any = content[Object.keys(content)[0]];
        const title: string = page.title;
        const extract: string = page.extract;
        return {
            "title": title,
            "extract": extract
        };
    }, [extracts]);

    const absolute_url = useMemo(() => {
        return "https://" + lang?.codeIso639 + ".wikipedia.org/w/api.php"
    },
        [lang]);

    const getWikipediSuggestions = useCallback(() => {
        axios({
            method: "get",
            url: absolute_url,
            params: {
                action: "query",
                list: "search",
                prop: "info",
                inprop: "url",
                utf8: "",
                format: "json",
                srlimit: "5",
                srsearch: wordName,
                origin: "*",
            },
        }
        )
            .then((res) => {
                const suggestions: any[] = res.data.query.search;
                getWikipediExtract((suggestions[0]) ? suggestions[0].title : wordName)
                setSuggestions(suggestions);
            }).catch((error) => {
                console.error("err8d6g4rdth getWikipediSuggestions failed", error)
            })
    }, [wordName, absolute_url]);

    /************************************************************ */

    function handleZoomClick(e: any) {
        setIsZoomed(!isZoomed)
        e.preventDefault();
    }

    /************************************************************ */
    
    const getWikipediExtract = useCallback((suggestionName: string = "Poulet") => {
        axiosRetry(axios, {
            retries: 3,
            retryDelay: () => 500,
            retryCondition: () => true,
        });
        axios({
            method: "get",
            url: absolute_url,
            params: {
                format: "json",
                origin: "*",
                action: "query",
                prop: "extracts",
                exsectionformat: "wiki",
                titles: suggestionName,
            },
        }
        ).then((res) => {
            const data = res.data;
            setExtracts(data);
            
        }).catch((error) => {
            console.error("err6551re6er1 getWikipediExtract failed", error)
        })
    }, [absolute_url]);
    
    /************************************************************ */
    
    return (
        <>
            <div className={'wikipedia ' + (isZoomed ? "wikipedia-zoomed" : "wikipedia-zoomed-not") + " " + UtilService.getStatusCssClassName(isRoundOver, isRoundWon)}>
                {suggestions && extracts ?
                    <div>
                        <div className='float-end'>
                            {getWikipediaCollapseButton()}
                        </div>
                        <div className="wikipedia-scrollable-content">
                            <span>
                                <ul className='wikipedia-suggestions'>
                                    {suggestions && suggestions.map(({ title, snippet }: any, index: number) => {
                                        return <li key={index}>
                                            <b>■{title} {" "}</b>
                                            <p dangerouslySetInnerHTML={{ __html: snippet }}>
                                            </p>
                                        </li>
                                    })}
                                </ul>
                            </span>
                            <div className='wikipedia-page mt-2'>
                                <h2 className='wikipedia-page-title ' style={{ marginBottom: 0, marginRight: 15, lineHeight: 1 }}>
                                    {getExtractsToDisplay().title}
                                </h2>
                                <p dangerouslySetInnerHTML={{ __html: getExtractsToDisplay().extract }}>
                                </p>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <Loader cssClass="loader-sm" />
                    </div>
                }
            </div>
        </>
    );
}

export default Wikipedia;