import { useState, useEffect, useMemo } from 'react';
import Board from './Board';
import Keyboard from './Keyboard'
import Wikipedia from './Wikipedia';
import RoundUnveilLetter from './RoundUnveilLetter';
import RoundOverStatusAndButton from './RoundOverStatusAndButton'
import RoundStatusBar from './RoundStatusBar';
import LetterClass from '../../entity/LetterClass';
import KeyboardKeyClass from '../../entity/KeyboardKeyClass';
import UserData from '../../entity/UserDataClass';
import RoundClass from '../../entity/RoundClass';
import * as UtilService from '../../service/UtilService'
import * as RoundService from '../../service/RoundService'
import statusType from '../../type/RoundType';
import { useTranslation } from "react-i18next";
import useRoundUpdate from "../../hook/useRoundUpdate";
import useUpdateUserData from "../../hook/useUpdateUserData";
import useRoundKeyboardKeyClick from "../../hook/useRoundKeyboardKeyClick";
import Button from "../Button";
import Modal from 'react-bootstrap/Modal';

interface RoundProps {
    round: RoundClass,
    userData: UserData,
    setStartRound: any,
    changeUserDataParent: any,
    setError: any,
    isPromptableToAskToRegistar: boolean | undefined
}
function Round({
    round: { id, word, letters },
    userData,
    setStartRound,
    changeUserDataParent,
    setError,
    isPromptableToAskToRegistar
}: RoundProps): JSX.Element {
    const { t } = useTranslation();
    // const { lang } = useContext(LanguageContext);
    // words exploded
    const [roundLetters, setRoundLetters] = useState<LetterClass[]>(letters);
    // starts pristine
    const [status, setStatus] = useState<statusType>("pristine");
    // board tiles animation at the begining
    const [animationTilesInlineStep, setAnimationTilesInlineStep] = useState<string>("is-at-beginning");
    // 0 means perfect round
    const [lossesCount, setLossesCount] = useState<number>(0);
    const [isGemGained, setIsGemGained] = useState<Boolean>(false);
    // to animate
    const [isNewLevelReached, setIsNewLevelReached] = useState<Boolean>(false);
    const [letterIdToUnveil, setLetterIdToUnveil] = useState<number>();
    const [isModalShown, setIsModalShown] = useState<boolean>(true);
    const isOver = useMemo(() => RoundService.getIsRoundOver(status), [status]);
    const isWon = useMemo(() => RoundService.getIsRoundWonOrLost(status), [status]);
    const isLost = useMemo(() => RoundService.getIsRoundWonOrLost(status, false), [status]);
    const [setRoundUpdate] = useRoundUpdate();
    const { setRoundUpdateUserDataTutorial, setRoundUpdateUserDataGem } = useUpdateUserData();
    const [setRoundKeyboardKeyClick] = useRoundKeyboardKeyClick();

    useEffect(() => {
        setAnimations()
    }, []);

    useEffect(() => {
        console.log("word", word.name, "round", id)
    }, [word.name, id]);

    useEffect(() => {
        (isOver) &&
            // save round result on db
            setRoundUpdate(
                id,
                isWon,
                status,
                userData,
                changeUserDataParent,
                setIsGemGained,
                setIsNewLevelReached
            )

    }, [isOver, isWon, isLost]);

    /************************************************************ */
    function setUserDataGemToUpdateCost(cost: number) {
        // save gems spend on DB
        setRoundUpdateUserDataGem(
            setError,
            cost,
            userData,
            changeUserDataParent,
        )
    }
    /************************************************************ */
    function setKeyboardKeyClicked(keyboardKeyClicked: any) {
        // key clicked won or lost
        setRoundKeyboardKeyClick(
            keyboardKeyClicked,
            status,
            [...roundLetters],
            lossesCount,
            setLossesCount,
            setRoundLetters,
            setStatus,
        )
        if (!Boolean(userData.is_tutorial_done)) {
            // if tuto not done yet, it is
            setRoundUpdateUserDataTutorial(
                setError,
                userData,
                changeUserDataParent
            )
        }
    }
    /************************************************************ */

    function setAnimations() {
        // board tiles are positioned chronologically, first at begining, last at the end
        var timeoutIsAnimating: any = setTimeout(function () {
            setAnimationTilesInlineStep("is-animating")
            var timeoutIsAtEnd: any = setTimeout(function () {
                setAnimationTilesInlineStep("is-at-end")
            }.bind(timeoutIsAtEnd), 500);
        }.bind(timeoutIsAnimating), 700);
    }

    /************************************************************ */

    return (
        <>
            {id &&
                <div className={" round round-status-" + status + " animation-round-" + animationTilesInlineStep}>
                    <input
                        type="hidden"
                        name="word-snitch"
                        id="word-snitch"
                        value={word.name}
                    />
                    <RoundStatusBar
                        isGemGained={isGemGained}
                        isNewLevelReached={isNewLevelReached}
                        difficultyLevel={word.idDifficulty}
                        userData={userData}
                    />
                    <Board
                        isTutorialDone={Boolean(userData.is_tutorial_done)}
                        isRoundOver={isOver}
                        wordId={word.id}
                        difficultyLevel={word.idDifficulty}
                        letters={roundLetters}
                        handleClickPristineToRound={(l: number) => {
                            setLetterIdToUnveil(l)
                        }}
                    />
                    <RoundUnveilLetter
                        letterId={letterIdToUnveil}
                        userData={userData}
                        letters={[...roundLetters]}
                        setUserDataGemToUpdateCost={setUserDataGemToUpdateCost}
                        setLetters={setRoundLetters}
                    />
                    {isOver &&
                        <RoundOverStatusAndButton
                            isRoundOver={isOver}
                            isRoundWon={isWon}
                            handleClick={setStartRound}
                            isPerfect={status === "perfect"}
                        />
                    }
                    {!isOver &&
                        animationTilesInlineStep === "is-at-end" &&
                        <>
                            <Keyboard
                                roundId={id}
                                wordLanguage={word.language}
                                isRoundOver={isOver}
                                letters={roundLetters}
                                handleKeyboardKeyClick={
                                    (keyboardKey: KeyboardKeyClass, status: statusType) => {
                                        setKeyboardKeyClicked({
                                            "keyboardKey": keyboardKey,
                                            "status": status
                                        })
                                    }
                                }
                                handleKeyDownEnter={setStartRound}
                            />
                        </>
                    }
                    {isOver &&
                        <>
                            <Wikipedia
                                wordName={word.getName()}
                                isRoundOver={isOver}
                                isRoundWon={isWon}
                            />
                            {
                                isPromptableToAskToRegistar &&
                                <>
                                    <Modal show={isModalShown} onHide={() => { setIsModalShown(false) }}>
                                        <Modal.Body style={{ display: "flex" }}>
                                            {t("auth.upgrade_ask")}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            {
                                                <>
                                                    <Button isBright onClick={() => { setIsModalShown(false) }} >
                                                        {t("cancel")}
                                                    </Button>
                                                    <Button path="register/upgrade">
                                                        {t("auth.signup")}
                                                    </Button>
                                                </>
                                            }
                                        </Modal.Footer>
                                    </Modal>
                                </>
                            }
                        </>
                    }
                    {animationTilesInlineStep === "is-at-end" &&
                        <footer className={"footer " + UtilService.getStatusCssClassName(isOver, isWon)}>
                        </footer>
                    }
                </div >
            }
        </>
    )
}
export default Round;