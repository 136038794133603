import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import LetterClass from "../../entity/LetterClass";
import UserDataClass from "../../entity/UserDataClass";
import Button from "../Button";
import Modal from 'react-bootstrap/Modal';
import Gem from '../user-data/Gem';
import * as LetterService from '../../service/LetterService'

interface TileProps {
    letterId: number | undefined,
    userData: UserDataClass,
    letters: LetterClass[],
    setUserDataGemToUpdateCost: any,
    setLetters: any,
}

function RoundUnveilLetter({
    letterId,
    userData : {gems_count},
    letters,
    setUserDataGemToUpdateCost,
    setLetters,
}: TileProps): JSX.Element {

    const { t } = useTranslation();
    const [isModalShown, setIsModalShown] = useState<boolean>(false);
    const [hasUserEnoughGems, setHasUserEnoughGems] = useState<boolean>();
    const prices = useMemo((): number[] => [5, 15, 40, 90, 200], []);
    const priceLessExpensive = useMemo((): number => prices[0], [prices]);
    const priceMostExpensive = useMemo((): number => prices[prices.length - 1], [prices]);
    const lettersUnveiledCount = useMemo(() => LetterService.getUnveiled(letters).length, [letters]);
    const [cost, setCost] = useState<number>(priceLessExpensive);

    useEffect(() => {
        if (typeof letterId !== "undefined" && letterId >= 0) {
            console.log("setCost()...")
            setCost((lettersUnveiledCount >= prices.length) ?
                priceMostExpensive : prices[lettersUnveiledCount])

            // enough gems to spend or not ?
            setHasUserEnoughGems(gems_count >= cost)

            setIsModalShown(true);
        }
    }, [letterId]);

    return <>

        {letterId &&
            <Modal show={isModalShown} onHide={() => { setIsModalShown(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title className="cap">
                        {t("game.unveiling_a_tile")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body  >
                    {
                        hasUserEnoughGems ?
                            <>
                                <span className="d-flex">
                                    {t("game.spend")}
                                    {" "}
                                    <Gem
                                        amount={cost}
                                        isGemGained={false}
                                        isShowLabel={false}
                                        isInline={true}
                                        isBkgBright={true}
                                    />
                                    {" "}
                                    {t("game.to_show_letter")}
                                    {" "}
                                    {"?"}
                                </span>

                            </>
                            /*****************************/
                            :
                            <>
                                <span className="d-flex">
                                    {t("game.you_only_have")}
                                    {" "}
                                    <Gem
                                        amount={gems_count}
                                        isGemGained={false}
                                        isShowLabel={false}
                                        isInline={true}
                                        isBkgBright={true}
                                    />
                                    {" "}
                                </span>
                                <span className="d-flex">
                                    {t("game.but_need")}
                                    <Gem
                                        amount={cost}
                                        isGemGained={false}
                                        isShowLabel={false}
                                        isInline={true}
                                        isBkgBright={true}
                                    />
                                </span>
                            </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        hasUserEnoughGems ?
                            <>
                                <Button isBright onClick={() => { setIsModalShown(false) }} >
                                    {t("cancel")}
                                </Button>
                                <Button onClick={() => {
                                    setIsModalShown(false)
                                    const letter: LetterClass = letters[letterId - 1]
                                    letters.forEach((l: LetterClass) => {
                                        l.content === letter.content && (l.status = "unveiled")
                                    });
                                    setUserDataGemToUpdateCost(cost);
                                    setLetters(letters);
                                }}>
                                    {t("game.spend")}
                                </Button>
                            </>
                            :
                            <>
                                <Button onClick={() => { setIsModalShown(false) }}>
                                    ok
                                </Button>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        }
    </>
}
export default RoundUnveilLetter;