import { useForm } from 'react-hook-form';
import PageLayout from '../../layouts/PageLayout';
import { useTranslation } from "react-i18next";
import FormInputError from '../FormInputError';

function PasswordForgotten() {
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });
    const { t } = useTranslation();

    const onSubmit = (data: any) => {
        console.log("onSubmit", data);
    };

    return (
        <PageLayout title={t("auth.password_forgotten")} pageName="password_forgotten" hasClosingCross>
            <p>
                {/* If your e-mail is registered, an e-mail will be sent to create a new password. */}
                {t("auth.password_forgotten_content")}
            </p>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>

                <fieldset className='row'>
                    <legend className='col-3'>
                        {t("auth.email")}
                    </legend>
                    <div className="col-9">
                        <input type="email" placeholder={t("auth.placeholder_email")}
                            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
                            required />
                        {
                        errors.email && 
                        <FormInputError error={errors.email} />
                        }
                    </div>
                </fieldset>

                <div className='my-2'>
                    <button type="submit" className='button-custom'>
                        {t("auth.send_recovery")}
                    </button>
                </div>

            </form>

        </PageLayout>

    );
}

export default PasswordForgotten;