import { useState, useEffect, useContext } from 'react';
import { ApiContext } from '../../context/ApiContext';
import { Navigate } from "react-router-dom";
import PageLayout from '../../layouts/PageLayout';
import { useTranslation } from "react-i18next";
import { LanguageContext } from '../../context/LanguageContext';
import * as UtilService from '../../service/UtilService'
import Captcha from './Captcha';
import * as UserService from '../../service/UserService'
import { consumeApi } from '../../service/ApiService'
import ErrorCustom from '../ErrorCustom';


function LoginGuest() {
    const { user, dispatchUser } = useContext(ApiContext);
    const { lang } = useContext(LanguageContext);
    const { t } = useTranslation();
    const [captchaToken, setCaptchaToken] = useState<string>("");
    const [error, setError] = useState<any>();
    // ***********************************

    useEffect(() => {
        captchaToken && setRegisterGuest()
    }, [captchaToken]);

    function onChange(v: string) {
        console.log(v)
        setCaptchaToken(v)
    }

    /***************************************** */

    function setRegisterGuest() {
        console.log('captchaToken', captchaToken);
        var fd = new FormData();
        fd.append('captchaToken', captchaToken);

        consumeApi(
            "post",
            "register-guest",
            user?.token,
            "",
            {},
            fd
        )
            .then(function (apiResponse) {
                dispatchUser({
                    type: "fillWithApiResponse",
                    payload: {
                        apiResponse
                    }
                });
            })
            .catch(function (err) {
                setError(err)
            });
    }

    /***************************************** */

    return (
        <>
            <ErrorCustom err={error} />

            <PageLayout title={t("auth.captcha_title")} pageName="signup" hasClosingCross>
                <p>
                    {t("auth.captcha_content")}
                </p>
                <Captcha onChange={onChange} />
            </PageLayout>
            {user && UserService.isLoggedIn(user) &&
                <Navigate to={UtilService.getRelativeUrl(lang, "game")} />
            }
        </>
    );
}
export default LoginGuest;