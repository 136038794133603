
import TileType from '../../type/TileType';
import LetterClass from '../../entity/LetterClass';

interface KeyboardKeyProps {
    letter: LetterClass;
    status: TileType;
}

function KeyboardKey({ letter, status }: KeyboardKeyProps): JSX.Element {

    return <>
        {status === "pristine" && 
            letter
        }
    </>
}

export default KeyboardKey;