import { Link } from "react-router-dom";
import { useContext, Children } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import '../i18n';
import { useTranslation } from "react-i18next";
import * as UtilService from '../service/UtilService'
// import {  } from 'react';


interface Props {
    children: any
    path?: string
    // label?: string
    isBright?: Boolean
    onClick?: any
    isPulsing?: Boolean
}

function Button({
    children,
    path = "",
    // label = "",
    isBright = false,
    onClick = null,
    isPulsing = false
}: Props): JSX.Element {

    const { t } = useTranslation();
    const { lang } = useContext(LanguageContext);

    const getCssClass = (): string | undefined =>
        "cap navbar-brand button-custom " + (isPulsing && " pulse ") + (isBright && " button-custom-bright");


    const getUrl = (): string =>
        UtilService.getRelativeUrl(lang, path);


    // can be a link (href) or a button (onclick)
    return <>
        {(path && !onClick) ?
            <Link
                to={getUrl()}
                relative="route"
                className={getCssClass()}

            >
                {children}
            </Link>
            :
            <button
                className={getCssClass()}
                onClick={() => {
                    onClick()
                }}
            >
                {children}
            </button>
        }
    </>
}
export default Button;