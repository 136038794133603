import * as UtilService from './UtilService'
import * as LanguageService from './LanguageService'
import RoundType from '../type/RoundType';
import LetterClass from '../entity/LetterClass';
import LanguageClass from '../entity/LanguageClass';
import Word from '../entity/WordClass';
import RoundClass from '../entity/RoundClass';
import { AxiosResponse } from 'axios';
import User from '../entity/UserClass';
import { consumeApi } from '../service/ApiService'

const getIsRoundWonOrLost = (status: RoundType, isWonOrLost: Boolean = true): Boolean =>
    (isWonOrLost) ? (status==="won" || status==="perfect") : (status==="lost");

export const fetchRoundUpdate = async (id: number, user: User | undefined, fd: any): Promise<any> => {
    const response: AxiosResponse<any> = await consumeApi(
        "post",
        "round/" + id,
        user?.token,
        "",
        {},
        fd
    )
    return response.data;
};

// const getIsPerfect = (lossesCount: number): Boolean => !!!lossesCount;

const getIsRoundOver = (s: RoundType): boolean =>
    Boolean(getIsRoundWonOrLost(s) || getIsRoundWonOrLost(s, false));

function getStatusIfAllLettersAre(letters: LetterClass[], lossesCount:number = 0): RoundType {
    // console.log("getStatusIfAllLettersAre", "lossesCount", lossesCount)
    let wons: number = 0
    let losts: number = 0
    for (const letter of letters) {
        if (letter.status === "won" || letter.status === "unveiled") {
            wons++;
            if (wons === letters.length) {
                return !!!lossesCount ? "perfect" : "won"
            }
        } else if (letter.status === "lost") {
            losts++;
            if (losts === letters.length) return "lost";
        }
    }
    return "pristine";
}

function getOneRandomPristineLetterKey(letters: LetterClass[]): number {
    let lettersIndexes: number[] = [];
    letters.forEach((l:LetterClass, i)=>{
        l.status === "pristine" && lettersIndexes.push(i)
    })
    lettersIndexes = UtilService.shuffle(lettersIndexes)
    return !lettersIndexes.length ? -1 : lettersIndexes[0];
}

function reduceRound(r: RoundClass, action: any): any {
    const { type, payload : {isFirstRoundKeyboardClicked, data} } = action;
    switch (type) {
        case "null":
            return null;
        // case "empty":
        //     return new RoundClass();
        case "start":
            const w = data.keyword;
            const language: LanguageClass = LanguageService.getLanguageFromId(w.id_language)
            const word = new Word(
                w.id,
                w.content,
                w.is_international_culture,
                w.is_proper_noun,
                w.id_difficulty,
                language
            );
            const r = data.round;
            const roundObject = new RoundClass(
                r.id,
                word,
                isFirstRoundKeyboardClicked
            );
            return roundObject;
        default:
            throw new Error("type is wrong : " + type);
    }
}

export {
    getIsRoundWonOrLost,
    // getIsPerfect,
    getIsRoundOver,
    getStatusIfAllLettersAre,
    getOneRandomPristineLetterKey,
    reduceRound
};