import { Link } from "react-router-dom";
import { useContext, Children } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import '../i18n';
import { useTranslation } from "react-i18next";
import * as UtilService from '../service/UtilService'
// import {  } from 'react';
import CookieConsent from "react-cookie-consent";

function CookieConsentContainer(): JSX.Element {

    const { t } = useTranslation();
    const { lang } = useContext(LanguageContext);

    return <>
        <CookieConsent
                location="bottom"
                disableStyles={true}
                buttonText={t("agree")}
                declineButtonText={t("cancel")}
                declineCookieValue={t("cancel")}
                enableDeclineButton
                cookieName="myAwesomeCookieName2"

                style={{
                    background: "#000",
                    opacity: .8,
                    position: "absolute",
                    // width: "90%",
                    left: 15,
                    right: 15,
                    borderRadius: 15,
                    color: "#ccc",
                    // top: 15
                    bottom: 15
                }}
                buttonStyle={{
                    background: "#3dffdf",
                    color: "#000"
                }}
                declineButtonStyle={{
                    background: "#ccc",
                    color: "#000"
                }}
                containerClasses="p-4"
                declineButtonClasses="button-custom cap mt-3 mr-3"
                buttonClasses="button-custom  cap mt-3 ml-3"
                onDecline={() => {
                    window.location.href = 'https://www.wikipedia.org';
                  }}

                expires={150}
            >

                {t("cookies_warning")}
            </CookieConsent>
    </>
}
export default CookieConsentContainer;