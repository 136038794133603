import {  useRef } from 'react';
// import { LanguageContext } from '../../context/LanguageContext';
import '../../i18n';
// import { useTranslation } from "react-i18next";
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import {    CAPTCHA_CONTAINER_ID} from '../../Constants';

interface Props {
    onChange?: any
}

function Captcha({
    onChange = (value: any) => {
        // console.log("value ::::::::::::::::::::: ", value)
    } }: Props) {
    const recaptchaRef = useRef();


    return (
        <>
            <form  id={CAPTCHA_CONTAINER_ID} data-cy={CAPTCHA_CONTAINER_ID} >
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                    onChange={onChange}
                />
            </form>
        </>
    );
}

export default Captcha;