class LevelClass {

    public id: number
    public icon: string
    public label: string
    public color: string
    public percentage_probability_difficulty_1: number;
    public percentage_probability_difficulty_2: number;
    public percentage_probability_difficulty_3: number;
    public percentage_probability_difficulty_4: number;

    public constructor(

        id: number,
        icon: string,
        label: string,
        color: string,
        percentage_probability_difficulty_1: number,
        percentage_probability_difficulty_2: number,
        percentage_probability_difficulty_3: number,
        percentage_probability_difficulty_4: number

    ) {
        this.id = id;
        this.icon = icon;
        this.label = label;
        this.color = color;
        this.percentage_probability_difficulty_1 = percentage_probability_difficulty_1;
        this.percentage_probability_difficulty_2 = percentage_probability_difficulty_2;
        this.percentage_probability_difficulty_3 = percentage_probability_difficulty_3;
        this.percentage_probability_difficulty_4 = percentage_probability_difficulty_4;
        
    }
}

export default LevelClass;