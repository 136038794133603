import { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import ButtonPlay from '../ButtonPlay';
import PageLayout from '../../layouts/PageLayout';
import '../../i18n';
import { useTranslation } from "react-i18next";
import Manual from '../Manual';
import HelpDifficultyGems from '../HelpDifficultyGems';

function Help() {

    const { lang } = useContext(LanguageContext);
    const { t } = useTranslation();

    function getExampleContent(): any {
        return ((lang?.codeIso639 === "en") &&
            (<>
                The word <span className='word-like'>FAIR</span> can give images about :
                <ul className='default'>
                    <li>Treating someone right</li>
                    <li>William Fair, who landed in Virginia in 1704</li>
                    <li>The village of Fair in France</li>
                    <li>The liquor brand Fair</li>
                    <li>Carnival, public event with games or competitions.</li>
                    <li>Having light skin and/or hair</li>
                </ul>
            </>))
            ||
            ((lang?.codeIso639 === "fr") &&
                (<>
                    Le mot <span className='word-like'>ORANGE</span> peut donner des images concernant:
                    <ul className='default'>
                        <li>La couleur orange</li>
                        <li>Le fruit</li>
                        <li>La ville d'Orange dans le Vaucluse</li>
                        <li>La marque de télécommunications Orange</li>
                    </ul>
                </>))

    }

    return (
        <PageLayout title={t("help")} isCap={true} pageName="help" hasClosingCross>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <Manual />
                </div>
                <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                    <HelpDifficultyGems />
                    <div className="mt-4">
                        {getExampleContent()}
                    </div>
                </div>
            </div>
            <div className="row mt-3 mb-5">
                <div className="col">
                    <div className='d-flex justify-content-center pt-2'>
                        <ButtonPlay />
                    </div>
                </div>
            </div>
        </PageLayout>
    );
}

export default Help;