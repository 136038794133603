// import { useContext, useState, useEffect } from 'react';
// import { useForm } from 'react-hook-form';
// import { ApiContext } from '../../context/ApiContext';
// // import { ApiContext } from '../../context/ApiContext';
// import { LanguageContext } from '../../context/LanguageContext';
// import { Link } from "react-router-dom";
// import FormInputError from '../FormInputError';
// import ErrorCustom from '../ErrorCustom';
// import { useTranslation } from "react-i18next";
// import * as UtilService from '../../service/UtilService'
// import * as LanguageService from '../../service/LanguageService'
// import { consumeApi } from '../../service/ApiService'
// import PageLayout from '../../layouts/PageLayout';
// import { Navigate, redirect, useNavigate } from "react-router-dom";
// import {LANGUAGES} from '../../Constants';


function LoginSuccess() {
    
    

    return (
        <>
            LoginSuccessLoginSuccessLoginSuccessLoginSuccess
        </>
    );
}
export default LoginSuccess;