import TileType from '../../type/TileType';
// import { ConfigContext } from '../../context/ConfigContext';
import ButtonCross from '../ButtonCross';
import { useState, useEffect } from 'react';
import { BACKEND_IMAGES_URL } from '../../context/ConfigContext';

interface TileProps {
    // isTutorialDone sets all tiles not clickable, only keyboard is clickable on tuto mode
    isTutorialDone: boolean;
    isRoundOver: boolean;
    wordId: number;
    letterId: number;
    letter: string;
    status: TileType
    handleClickPristineToBoard: any
}

function Tile({
    isTutorialDone,
    isRoundOver,
    wordId,
    letterId,
    letter,
    status,
    handleClickPristineToBoard
}: TileProps): JSX.Element {

    // const { baseServerUrl } = useContext(ConfigContext);
    const [isImageZoomed, setIsImageZoomed] = useState(false);
    const [animationClass, setAnimationClass] = useState('INIT');

    useEffect(() => {
        if (status !== "pristine") {
            setAnimationClass('animation-flip');
            const timeOut = setTimeout(() => {
                setAnimationClass('RE-INIT');
            }, 350);
            return () => clearTimeout(timeOut);
        }
    }, [status]);

    function getImgUrl(isStyle = false): string {
        // const baseServerUrlFitting: string = baseServerUrl.replace("/public", "");
        // const baseServerUrlFitting: string = baseServerUrl.startsWith("/public") ?
        //     baseServerUrl.substring(7) :
        //     baseServerUrl;
        console.log("getImgUrl ... REACT_APP_BACKEND_IMAGES_URL", BACKEND_IMAGES_URL)
        const img_url = `${BACKEND_IMAGES_URL}${wordId}/${letterId}.jpg`;
        return isStyle ? `url(${img_url})` : img_url;
    }

    function handleClickLost(e: any) {
        setIsImageZoomed(!isImageZoomed)
        e.preventDefault();
    }

    return <div className={'tile tile-status-' + status + " " + animationClass}   >

        {(isRoundOver || status === "won" || status === "unveiled") &&
            <div className='tile-letter'>
                {letter}
            </div>
        }
        {(isRoundOver || status === "lost") &&


            <div
                className={'tile-image ' + (isImageZoomed ? 'tile-image-zoomed' : '')}
                style={{ backgroundImage: `url(${getImgUrl()})` }} >
                {
                    isTutorialDone &&
                    <ButtonCross handleClick={handleClickLost} />
                }
            </div>

        }
        {status === "pristine" &&
            <div className='tile-pristine'>
                {
                    isTutorialDone &&
                    <a href="#" onClick={() => handleClickPristineToBoard(letterId)}></a>
                }
            </div>
        }

    </div>
}
export default Tile;