import {  useContext,  ReactNode } from 'react';
import {  Link } from "react-router-dom";
import { LanguageContext } from '../context/LanguageContext';
import * as UtilService from '../service/UtilService'

interface Props {
    to: string,
    icon?: string,
    children: ReactNode | null;
}

function NavigationItem({ to, icon, children }: Props) {

    const { lang } = useContext(LanguageContext);

    return (
        <li className={to} >
            <Link to={UtilService.getRelativeUrl(lang, to)} relative="route" className="navigation-item" >
                {icon && 
                    <div className='navigation-icon ' >
                        <img src={icon} alt={to} />
                    </div>
                }
                <div
                    className='cap'
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                >
                    {children}
                </div>
            </Link>
        </li>

    );
}

export default NavigationItem;