import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ApiContext } from '../../context/ApiContext';
// import { ApiContext } from '../../context/ApiContext';
import { LanguageContext } from '../../context/LanguageContext';
import { Link } from "react-router-dom";
import FormInputError from '../FormInputError';
import ErrorCustom from '../ErrorCustom';
import { useTranslation } from "react-i18next";
// import * as UserService from '../../service/UserService'
import * as UtilService from '../../service/UtilService'
// import * as LanguageService from '../../service/LanguageService'
import { consumeApi } from '../../service/ApiService'
import PageLayout from '../../layouts/PageLayout';
import { useNavigate } from "react-router-dom";
// import LanguageClass from '../../entity/LanguageClass';
// import {LANGUAGES} from '../../Constants';

interface LoginProps {
    isBright?: Boolean
}
function Login({ isBright = false }: LoginProps) {
    const { register, handleSubmit, formState: { errors, isValid } } = useForm({ mode: 'onChange' });
    const { dispatchUser } = useContext(ApiContext);
    const { user } = useContext(ApiContext);
    const { lang } = useContext(LanguageContext);
    const defaultValues = { "email": "ddd@gmail.com", "password": "Blouga_1" }
    const { t } = useTranslation();
    const [error, setError] = useState<any>();
    const navigate = useNavigate();

    // ***********************************

    const onSubmit = (data: any) => {
        console.log("onSubmit()")
        setLogin(data.email, data.password);
    };
    // ***********************************

    function setLogin(
        email = defaultValues.email,
        password = defaultValues.password
    ) {
        console.log("setLogin()")

        var fd = new FormData();
        fd.append('email', email);
        fd.append('password', password);

        consumeApi(
            "post",
            "login",
            "",
            "",
            {},
            fd
        )
            .then(function (apiResponse) {
                console.log("logged in ...")
                dispatchUser({
                    type: "fillWithApiResponse",
                    payload: {
                        apiResponse
                    }
                });
            })
            .catch(function (err) {
                setError(err)
            });
    }

    return (
        <>
            <ErrorCustom err={error} />
            <PageLayout title={t("auth.login")} pageName="login" >
                {/* <h1>{t("auth.login")}</h1> */}
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <fieldset className='row mb-1'>
                        <label className='col-3' htmlFor="email">
                            {t("auth.email")}
                        </label>
                        <div className="col-9">
                            <input
                                type="email"
                                id="email"
                                placeholder={t("auth.placeholder_email")}
                                {...register("email", {
                                    required: {
                                        value: true,
                                        message: t("auth.email") + " " + t("required")
                                    },
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: t("auth.email") + " " + t("must_be_valid")
                                    }
                                })}
                                required />
                            <FormInputError error={errors.email} />
                        </div>
                    </fieldset>

                    <fieldset className='row mb-1'>
                        <label className="col-3" htmlFor="password">
                            {t("auth.password")}
                        </label>
                        <div className="col-9">
                            <input
                                type="password"
                                id="password"
                                {...register("password", {
                                    required: {
                                        value: true,
                                        message: t("auth.password") + " " + t("required")
                                    },
                                    minLength: {
                                        value: 7,
                                        message: "7 " + t("characters_minimum")
                                    }
                                })}
                                required />
                            <FormInputError error={errors.password} />
                        </div>
                    </fieldset>

                    <div className="row my-3">
                        <div className="col">
                            <button
                                type="submit"
                                disabled={!isValid}
                                className={'button-custom cap ' + (isBright && " button-custom-bright ")}
                            >
                                {t("auth.login")}
                            </button>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col">
                            <Link to={UtilService.getRelativeUrl(lang, "password-forgotten")} relative="path">
                                {t("auth.password_forgotten")} ?
                            </Link>
                        </div>
                    </div>

                </form>
            </PageLayout>
        </>
    );
}
export default Login;