import { useMemo } from 'react';
import statusType from '../../type/RoundType';
import * as UtilService from '../../service/UtilService'
import Graphemer from 'graphemer';
import { useTranslation } from "react-i18next";

interface TileProps {
    isWon: Boolean;
    isPerfect: Boolean;
}

function RoundVictoryOrLostStatus({ isWon, isPerfect }: TileProps): JSX.Element {

    const splitter = new Graphemer();
    const { t } = useTranslation();

    function getIcon(status: statusType) {
        const icons: any = {
            won: "✨🌠✌️😸😻😽😺💋💓💖💗😃㋛㋡✺💫🌟✌️☝️♡☼💖💝💓✅😍😝😋😎😏🤩🥴️🥳️🥲🤴👸🙆🙆‍♂️🙆‍♀️🦸🦸‍♀️🏄🏄‍♂️🏄‍♀️🧑‍🎓👨‍🎓👩‍🎓🎆🎓🔆💰🕶🎩👑🎀💍💎🏅🎖🏵🥂💈🌸✳️🍾🏆🤘🥇👌🚀"
            ,
            lost: "☠☢🙊☣😿😾🙀😔♠☁⤵☠☢☣💔❌✗✖😒😞😣😢😭😥😩😱😠😤😖💀👺🥶️🤦‍♂️🤦🤦‍♀️🙅🙅‍♂️🙅‍♀️🧟🧟‍♂️🧟‍♀️🔪💣⚰️🕸💩🥀🚫⛔⚠️🚧🕷👎🤐🤢🏴🤬🤮📌🏚"
        }
        const a: string[] = splitter.splitGraphemes(icons[status]);
        const i = UtilService.getRandomArrayItem(a)
        return i;
    }

    const wonIcon = useMemo(() => getIcon("won"), [getIcon]);
    const lostIcon = useMemo(() => getIcon("lost"), [getIcon]);

    return <div className='round-victory-or-lost-status'   >

        {isWon && <div className='won'>
            <h2 className='d-flex align-items-center'>
                <span className='icon'>
                    {wonIcon}
                </span>
                <span className='title-content low'>
                    {isPerfect ?
                        t("game.perfect")!
                        :
                        t("game.won", { count: 1 })
                    }
                </span>
            </h2>
        </div>}

        {!isWon && <div className='lost'>
            <h2 className='d-flex align-items-center'>
                <span className='icon'>
                    {lostIcon}
                </span>
                <span className='title-content low'>
                    {t("game.lost", { count: 1 })}
                </span>
            </h2>
        </div>}

    </div>
}
export default RoundVictoryOrLostStatus;