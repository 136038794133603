import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
interface Props {
    hasChanged: any
}
function RouteHasChanged({hasChanged}:Props) {
    const location = useLocation();
    useEffect(() => {
        hasChanged();
    }, [location]);
    return (
       <></>
    );
}
export default RouteHasChanged;