import LanguageSelector from '../LanguageSelector';
import '../../i18n';
import { useTranslation } from "react-i18next";
import PageLayout from '../../layouts/PageLayout';
import Manual from '../Manual';

function GameLanguageSelector() {
    const { t } = useTranslation();

    return (
        <>
            <PageLayout title={t('game.let_s_play')} pageName="home">
                <div className='mt-2'>
                    <LanguageSelector />
                </div>
                <Manual />
            </PageLayout>
        </>
    );
}

export default GameLanguageSelector;