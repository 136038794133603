import TileType from '../type/TileType';

class LetterClass {
    public constructor(
        content: string,
        status:TileType = "pristine"
        ) {
        this.content = content.toUpperCase();
        this.status = status;
    }
    public content: string;
    public status: TileType;
}
export default LetterClass;