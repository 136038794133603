import {useMemo } from "react";
import Tile from './Tile';
import Letter from '../../entity/LetterClass';
import Difficulty from './Difficulty';
import '../../i18n';
import { useTranslation } from "react-i18next";

interface BoardProps {
  isTutorialDone: boolean,
  isRoundOver: boolean,
  wordId: number,
  difficultyLevel:number,
  letters: Letter[],
  handleClickPristineToRound: any
}

function Board({ 
    isTutorialDone,
    isRoundOver, 
    wordId, 
    difficultyLevel, 
    letters, 
    handleClickPristineToRound 
}: BoardProps): JSX.Element {

  const wordLength = useMemo(() => getWordLength(), [letters.length]);
  const { t } = useTranslation();

//   const getWordLength = (): number => letters.length;
  function getWordLength(): number { return letters.length };
  
  function handleClickPristineToBoard(letterId:Letter) {
    // in tuto mode, only possible to click on keyboard first
    handleClickPristineToRound(letterId);
  }

  return <div className={`board board-word-length-${wordLength} `}>

      <h1 className="board-title"> 
        {letters.length} {t('game.letter', {count:2})}
      </h1>

      <ul >
        {letters.map(({content, status}: Letter, index: number) => {
            // as board tiles as word letters
          return <li key={index}>
              <Tile
                isTutorialDone={isTutorialDone}
                isRoundOver={isRoundOver}
                wordId={wordId}
                letterId={index + 1}
                letter={content}
                status={status}
                handleClickPristineToBoard={handleClickPristineToBoard}
              />
          </li>
        })}
      </ul>
      {/* Before tiles are done animated, show the word difficulty in background of board */}
      <Difficulty levelId={difficultyLevel} />
  </div>
}
export default Board;
