import { createContext } from 'react';
import User from '../entity/UserClass';

type ApiProps = {
    url : string;
    
    user : User;
    dispatchUser : any;
    
}

export const ApiContext = createContext<Partial<ApiProps>>({});