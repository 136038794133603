import UserDataClass from './UserDataClass';

class UserClass {
    id: string = "";
    name: string = "";
    email: string = "";
    token: string = "";
    email_verified_at: string = "";
    userData: UserDataClass|undefined;

    // constructor() {}
    
}
export default UserClass;