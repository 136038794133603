import { useState } from 'react';
import * as UserService from '../service/UserService'
import { consumeApi } from '../service/ApiService'
import UserClass from '../entity/UserClass';

const useLanguagePreferedSave = () => {

    const [error, setError] = useState<any>();

    function saveLanguage(
        user:UserClass, 
        languageId:number
    ) {

        // console.log("useLanguagePreferedSave languageId : ", languageId)

        if (UserService.isGuest(user)) return false;

        var fd = new FormData();
        fd.append('languageId', String(languageId));

        consumeApi(
            "post",
            "language-prefered",
            user.token,
            "",
            {},
            fd
        )
            .then(function (apiResponse) {
                console.log("Prefered language well set", apiResponse)
            })
            .catch(function (err) {
                setError(err)
            });
    }

    return [saveLanguage]
};
export default useLanguagePreferedSave;