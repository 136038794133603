
import HttpMethodType from '../type/HttpMethodType';
import axios, { AxiosResponse } from 'axios';
import { getApiUrl } from '../context/ConfigContext';
import axiosRetry from 'axios-retry';
import ApiErrorClass from '../entity/ApiErrorClass';

export const consumeApi = async (
    method: HttpMethodType,
    segment: string,
    userToken: string = "",
    lang: string = "",
    params: any = {},
    data: any = {}
): Promise<any> => {
    try {
        axiosRetry(axios, {
            retries: 2,
            retryDelay: () => 200,
            retryCondition: () => true,
        });
        const api_url: string = getApiUrl(segment, lang);
        console.log("consumeApi() api_url", api_url)
        let axiosParams: any = {}
        axiosParams.url = api_url;
        axiosParams.method = method;
        if (userToken) axiosParams.headers = {'Authorization': `Bearer ${userToken}`,}
        axiosParams.params = params
        axiosParams.data = data
        console.log("consumeApi()", "segment", segment)
        const response: AxiosResponse<any> =
            await axios(
                axiosParams
            );
        return response;
    } catch (err: any ) {
        const response:any = err.response;
        const response_data:any = response.data;
        const customError:ApiErrorClass = new ApiErrorClass(
            err.message,
            response.status,
            response.statusText,
            response_data.id,
            response_data.slug,
            response_data.message
        )
        // alert(customError);
        throw customError;
    }
};