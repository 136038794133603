import * as UtilService from './UtilService'
import AuthType from '../type/AuthType';
import UserDataClass from '../entity/UserDataClass';
import Level from '../entity/LevelClass';
import User from '../entity/UserClass';

// *******************************************************

function setDataForGuest(
    user: User,
    id: string = "",
    name: string = "",
): User {
    if (!id || !name) throw new Error(`err45f6e5fer5 User props missings id: ${id} name: ${name}`);
    user.id = id;
    user.name = name;
    return user;
}

// *******************************************************
function setData(
    user: User,
    id: string = "",
    name: string = "",
    email: string = "",
    email_verified_at: string = ""
): User {
    if (!id || !name || !email) throw new Error(`err8r4g6r1tg User props missings id: ${id} name: ${name} email: ${email}`);
    if (email !== "no-email" && !validateEmail(email)) throw new Error("erre56g4er6ge email not valid : " + email);
    user.email = email;
    user.email_verified_at = email_verified_at;
    user = setDataForGuest(user, id, name)
    return user;
}
// *******************************************************

function setUserData(user: User, userData: UserDataClass | undefined) {
    user.userData = userData;
    return { ...user };
}
// *******************************************************

const getUserData = (user: User): UserDataClass | undefined => user.userData;
// *******************************************************

const isUserData = (user: User): boolean => Boolean(user.userData);
// *******************************************************

function setUserDataFromDB(
    user: User,
    {
        user_id,
        language_id,
        is_tutorial_done,
        level,
        level_biggest_reached,
        rounds_played_count,
        rounds_won_count,
        gems_count,
        victory_percentage,
        level_step,
        level_step_in_level,
        level_step_in_level_percentage,
        level_id,
        level_id_biggest_reached
    }: UserDataClass
): User {
    // const ud_level = user_data.level;
    const ud_level = new Level(
        level.id,
        level.icon,
        level.label,
        level.color,
        level.percentage_probability_difficulty_1,
        level.percentage_probability_difficulty_2,
        level.percentage_probability_difficulty_3,
        level.percentage_probability_difficulty_4
    );
    // const ud_level_biggest_reached = user_data.level_biggest_reached;
    const ud_level_biggest_reached = new Level(
        level_biggest_reached.id,
        level_biggest_reached.icon,
        level_biggest_reached.label,
        level_biggest_reached.color,
        level_biggest_reached.percentage_probability_difficulty_1,
        level_biggest_reached.percentage_probability_difficulty_2,
        level_biggest_reached.percentage_probability_difficulty_3,
        level_biggest_reached.percentage_probability_difficulty_4
    );

    return setUserData(user, new UserDataClass(
        user_id,
        language_id,
        is_tutorial_done,
        rounds_played_count,
        rounds_won_count,
        gems_count,
        victory_percentage,
        level_step,
        level_step_in_level,
        level_step_in_level_percentage,
        level_id,
        ud_level,
        level_id_biggest_reached,
        ud_level_biggest_reached
    ));
}
// *******************************************************

const getEmailUsername = (user: User): string => user.email.split("@")[0];
// *******************************************************

const isLoggedIn = (user: User): boolean => Boolean(user.token);

// *******************************************************
const isGuest = (user: User): boolean => isLoggedIn(user) && Boolean(!user.email);

// *******************************************************
function getStatus(user: User): AuthType {
    if (!isLoggedIn(user)) return "not-logged-in";
    if (isGuest(user)) return "logged-in-as-guest"
    return "logged-in-as-authenticated"
}
// *******************************************************

const isStatus = (user: User, s: string): boolean => s === getStatus(user)
// *******************************************************

const validateEmail = (email: string): any =>
    String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

// *******************************************************
function getUserFilledWithApiResponse(
    { data: { data: { token, id, name, email, user } } }: any,
): User {
    let u: User = new User();
    u.token = token;
    u = (email) ?
        setData(u, id, name, email) :
        setDataForGuest(u, id, name);
    u = (user?.user_data) ?
        setUserDataFromDB(u, user?.user_data) :
        u;
    UtilService.setCookie(
        "lexiar_user_token",
        token,
    )
    return u;
}
// *******************************************************
// function setTutorialDone(user:User){
//     user.userData.is_tutorial_done = 1;
// }
// *******************************************************
                
function reduceUser(user: User, action: any): User {
    const { type, payload: { apiResponse, userData } } = action;
    return (type === "fillWithApiResponse" && getUserFilledWithApiResponse(apiResponse)) ||
        (type === "setUserDataFromDB" && setUserDataFromDB(user, userData)) ||
        (type === "setUserData" && setUserData(user, userData)) ||
        new User();
}
// *******************************************************

export {
    setDataForGuest,
    setData,
    setUserData,
    reduceUser,
    getUserData,
    isUserData,
    setUserDataFromDB,
    getEmailUsername,
    isLoggedIn,
    isGuest,
    getStatus,
    isStatus,
    validateEmail,
    getUserFilledWithApiResponse
};