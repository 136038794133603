import { useTranslation } from "react-i18next";

interface Props {
    percentage: number | undefined,
    // rounds_won_count: number | undefined,
    // rounds_played_count: number | undefined,
    doesShowLabel?: boolean
    doesShowDetails?: boolean
}

function VictoryPercentage({
    percentage,
    // rounds_won_count,
    // rounds_played_count,
    doesShowLabel = true,
    doesShowDetails = true
}: Props): JSX.Element {

    const { t } = useTranslation();

    const isAboveAverage = (): boolean => !!(percentage && percentage >= 50);

    return <>

        {doesShowDetails &&
            <>
                {t("victory_percentage")} :
                {" "}
            </>
        }

        {doesShowLabel &&
            <span className='color-bright'>

                {t("game.won", { count: 2 }) + " "}
            </span>

        }

        <span className={isAboveAverage() ? 'status status-won status-won-bright' : 'color-bright'}>
            <span>
                {percentage}
                %
            </span>
            <span>
                {
                    isAboveAverage() ?
                        <span>➚</span>
                        :
                        <span>➘</span>
                }
            </span>
        </span>
    </>
}
export default VictoryPercentage;