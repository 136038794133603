import { useContext } from 'react';
import { ApiContext } from '../context/ApiContext';
import UserData from '../entity/UserDataClass';
import { consumeApi } from '../service/ApiService'

const useUpdateUserData = () => {

    const { user } = useContext(ApiContext);
    // const [error, setError] = useState<any>();

    // ****************************************************

    function setRoundUpdateUserDataTutorial(
        setError:any,
        userData: UserData,
        changeUserDataParent: any,
    ) {
        consumeApi(
            "post",
            "tutorial-done",
            user?.token,
        )
            .then((res: any) => {
                // no need to treat server data, only set tuto done
                userData.is_tutorial_done = true
                changeUserDataParent({ ...userData });
            }).catch((err: any) => {
                setError(err)
                // console.error("setRoundUpdateUserDataTutorial error", err)
            })
    }

    // ****************************************************

    function setRoundUpdateUserDataGem(
        setError:any,
        cost: number,
        userData: UserData,
        changeUserDataParent: any,
    ) {
        var fd = new FormData();
        const costStringified: string = "" + cost
        fd.append('cost', costStringified);
        consumeApi(
            "post",
            "round/gems-cost",
            user?.token,
            "",
            {},
            fd
        )
            .then((res: any) => {
                userData.gems_count = res.data.data.gems_count
                changeUserDataParent({ ...userData });
            }).catch((err: any) => {
                setError(err)
                // console.error("setRoundUpdateUserDataGem error", err)
            })
    }

    // ****************************************************

    return {setRoundUpdateUserDataTutorial, setRoundUpdateUserDataGem}
};
export default useUpdateUserData;