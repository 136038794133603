import ReactDOM from 'react-dom/client';
import './style/_index.sass';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

function getCssClassForSpecificUserAgent(): string {
    let a: string = navigator.userAgent.toLowerCase();
    return (a.includes("android") && a.includes("chrome") && "user-agent-android-chrome") ||
        (a.includes("android") && a.includes("firefox") && "user-agent-android-firefox") ||
        (a.includes("iphone") && a.includes("safari") && "user-agent-iphone-safari") ||
        (a.includes("iphone") && a.includes("chrome") && "user-agent-iphone-chrome") ||
        "user-agent-default"
}

root.render(
    //   <React.StrictMode>
    <div className={"app-container " + getCssClassForSpecificUserAgent()}>
        <App />
    </div>
    //   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// reportWebVitals(console.log);
