import cross from '../../image/cross-white.svg';

interface Props {
    handleClick: any;
    label?: string;
    cssClasses?: string;
}

function ButtonCross({ handleClick, label="×", cssClasses="" }: Props): JSX.Element {

    return <div className={'button-cross '}>
        <a href="#" onClick={handleClick} className={cssClasses + " "}>
            <span className='cross p-3'>
                <img src={cross} className='' alt="close image zoom" />
            </span>
        </a>
    </div>
}
export default ButtonCross;