import '../../i18n';
import { useTranslation } from "react-i18next";
import PageLayout from '../../layouts/PageLayout';
import { useState, useEffect, useContext } from 'react';
import { ApiContext } from '../../context/ApiContext';
import LevelClass from '../../entity/LevelClass';
import LevelUserData from '../user-data/Level';
import Gauge from '../user-data/Gauge';
import Difficulty from '../game/Difficulty';
import UserDataDetails from '../user-data/UserDataDetails';
import ButtonPlay from '../ButtonPlay';
import ErrorCustom from '../ErrorCustom';
import { consumeApi } from '../../service/ApiService'

function Level() {
    const { user } = useContext(ApiContext)
    const { t } = useTranslation();
    const [error, setError] = useState<any>();
    const [levels, setLevels] = useState<LevelClass[]>([]);

    useEffect(() => {
        getLevels()
    }, []);

    function getLevels() {
        consumeApi("get", "level")
            .then((res: any) => {
                const l: LevelClass[] = res.data.data.levels;
                setLevels(l)
            }).catch((err: any) => {
                setError(err);
            })
    }

    const isNotZero = (p: number): boolean => (p > 0);

    function isIdOverLimitToDisplay(currentId: number): boolean {
        let id: number = (user?.userData) ? user.userData.level_id_biggest_reached : 1;
        return (currentId > id);
    }

    return (
        <>
            <ErrorCustom err={error} />

            <PageLayout title={t("level.level", { count: 2 })} pageName="levels" hasClosingCross>
                <div className='dark-area'>
                    <h2 className='cap'>
                        {
                            ""
                            + t("difficulty.difficulty", { count: 1 })
                            + " "
                            + t("of", { count: 2 })
                            + " "
                            + t("game.word", { count: 2 })
                            + " "
                            + "(%)"
                        }
                    </h2>
                    <table>
                        <thead>
                            <tr>
                                <td>
                                </td>
                                <td>
                                    <Difficulty
                                        levelId={1}
                                        isLabelShown={false}
                                        isTextualMeaningShown={true}
                                    />
                                </td>
                                <td>
                                    <Difficulty
                                        levelId={2}
                                        isLabelShown={false}
                                        isTextualMeaningShown={true}
                                    />
                                </td>
                                <td>
                                    <Difficulty
                                        levelId={3}
                                        isLabelShown={false}
                                        isTextualMeaningShown={true}
                                    />
                                </td>
                                <td>
                                    <Difficulty
                                        levelId={4}
                                        isLabelShown={false}
                                        isTextualMeaningShown={true}
                                    />
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {levels &&
                                levels.map(function (level, i) {
                                    return <tr key={i}>
                                        <td className='pb-3'>
                                            {
                                                <LevelUserData
                                                    icon={level.icon}
                                                    label={level.label}
                                                    color={level.color}
                                                    iconSize="xl"
                                                    isIconFirst={true}
                                                    hasDropShadow={true}
                                                    isBlured={isIdOverLimitToDisplay(level.id)}
                                                />

                                            }
                                        </td>
                                        <td>
                                            {isNotZero(level.percentage_probability_difficulty_1) &&
                                                <>
                                                    {level.percentage_probability_difficulty_1}
                                                    <Gauge
                                                        percentage={level.percentage_probability_difficulty_1}
                                                        emptyColor="333"
                                                    />
                                                </>
                                            }
                                        </td>
                                        <td>
                                            {isNotZero(level.percentage_probability_difficulty_2) &&
                                                <>
                                                    {level.percentage_probability_difficulty_2}
                                                    <Gauge
                                                        percentage={level.percentage_probability_difficulty_2}
                                                        emptyColor="333"
                                                    />
                                                </>
                                            }
                                        </td>
                                        <td>
                                            {isNotZero(level.percentage_probability_difficulty_3) &&
                                                <>
                                                    {level.percentage_probability_difficulty_3}
                                                    <Gauge
                                                        percentage={level.percentage_probability_difficulty_3}
                                                        emptyColor="333"
                                                    />
                                                </>
                                            }
                                        </td>
                                        <td>
                                            {isNotZero(level.percentage_probability_difficulty_4) &&
                                                <>
                                                    {level.percentage_probability_difficulty_4}
                                                    <Gauge
                                                        percentage={level.percentage_probability_difficulty_4}
                                                        emptyColor="333"
                                                    />
                                                </>
                                            }
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>

                <div className="row mt-2">
                    <div className="col">
                        {user && user.userData &&
                            <UserDataDetails userData={user.userData} />
                        }
                    </div>
                </div>

                <div className="row mt-3 mb-5">
                    <div className="col d-flex justify-content-center">
                        <ButtonPlay />
                    </div>
                </div>
            </PageLayout>
        </>
    );
}
export default Level;