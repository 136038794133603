import { useState, useEffect, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { ApiContext } from '../../context/ApiContext';
import PageLayout from '../../layouts/PageLayout';
import { useTranslation } from "react-i18next";
import { Navigate, redirect } from "react-router-dom";
import AuthType from '../../type/AuthType';
import AuthUrlSegment from '../../type/AuthUrlSegment';
import { LanguageContext } from '../../context/LanguageContext';
import * as UtilService from '../../service/UtilService'
import PasswordStrengthBar from 'react-password-strength-bar';
import Loader from '../Loader';
import Captcha from './Captcha';
import FormInputError from '../FormInputError';
import * as UserService from '../../service/UserService'
import { consumeApi } from '../../service/ApiService'
import Alert from '../Alert';
import ErrorCustom from '../ErrorCustom';
// import Modal from 'react-bootstrap/Modal';
// import Button from "../Button";
import {
    NAME_VALID,
    EMAIL_VALID,
    PASS_VALID
} from '../../Constants';

interface RoundProps {
    status: AuthType
}

function Register({ status }: RoundProps) {
    const { register, watch, handleSubmit, formState: { errors, isValid } } = useForm({ mode: "onChange" });
    const { user, dispatchUser } = useContext(ApiContext);
    const { t } = useTranslation();
    const { lang } = useContext(LanguageContext);
    const [password, setPassword] = useState("");
    const [passwordScore, setPasswordScore] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [captchaToken, setCaptchaToken] = useState<string>("");
    const [error, setError] = useState<any>();
    const [alertMessage, setAlertMessage] = useState<any>();
    const passwordRef = useRef({});
    // const [isModalShown, setIsModalShown] = useState<boolean>(true);
    // const [modalMessage, setModalMessage] = useState<string>();

    passwordRef.current = watch("password", "");

    useEffect(() => {
        const subscription = watch((value, { name, type }) =>
            setPassword(value.password)
        )
        return () => subscription.unsubscribe()
    }, [watch])

    const onSubmit = (data: any) => {
        if (passwordScore < 3) {
            setAlertMessage(t("auth.password_not_secured_enough"))
        } else if (!captchaToken) {
            setAlertMessage(t("auth.api_err_captcha_inputs_validation"))
        } else {
            setRegister(data.name, data.email, data.password);
        }
    };
    // ***********************************
    const onChangeScore = (o: any) => {
        setPasswordScore(o)
    };
    // ***********************************
    const onCaptchaChange = (v: string) => {
        console.log(v)
        setCaptchaToken(v);
    };
    // ***********************************
    function setRegister(
        name = NAME_VALID,
        email = EMAIL_VALID,
        password = PASS_VALID,
    ) {
        setIsSubmitted(true)
        var fd = new FormData();
        fd.append('name', name);
        fd.append('email', email);
        fd.append('password', password);
        fd.append('password_retype', password);
        fd.append('captchaToken', captchaToken);

        let action: AuthUrlSegment = status==="not-logged-in" ? "register" : "upgrade-guest"

        consumeApi(
            "post",
            action,
            user?.token,
            "",
            {},
            fd
        )
            .then(function (apiResponse) {
                dispatchUser({
                    type: "fillWithApiResponse",
                    payload: {
                        apiResponse
                    }
                });
                console.warn("A confirmation e-mail has been sent to you, it contains a link you must click on to confirm your account.")
                return redirect(UtilService.getRelativeUrl(lang, "game"));
            })
            .catch(function (err) {
                setError(err)
            });
    }

    // ***********************************

    return (
        <>
            <ErrorCustom err={error} />

            <Alert message={alertMessage} setMessage={setAlertMessage}  />

            {user &&
                UserService.isStatus(user, "logged-in-as-authenticated") ?
                <Navigate to={UtilService.getRelativeUrl(lang, "game")} />
                :
                <PageLayout title={t("auth.signup")} pageName="signup" hasClosingCross>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate >
                        {/* *** name ******************************************************** */}
                        <fieldset className='row mb-1'>
                            <label className='col-3' htmlFor="name">
                                {t("name")}
                            </label>
                            <div className="col-9">
                                <input
                                    id="name"
                                    type="text"
                                    placeholder={t("auth.placeholder_name")}
                                    {...register("name",
                                        {
                                            required: {
                                                value: true,
                                                message: t("name") + " " + t("required")
                                            },
                                            minLength: {
                                                value: 2,
                                                message: "2 " + t("characters_minimum")
                                            },
                                            maxLength: {
                                                value: 16,
                                                message: "16 " + t("characters_maximum")
                                            }
                                        })}
                                    required />
                                <FormInputError error={errors.name} />
                            </div>
                        </fieldset>

                        {/* *** email ******************************************************** */}
                        <fieldset className='row mb-1'>
                            <label className='col-3' htmlFor="email">
                                {t("auth.email")}
                            </label>
                            <div className="col-9">
                                <input
                                    type="email"
                                    id="email"
                                    placeholder={t("auth.placeholder_email")}
                                    {...register("email",
                                        {
                                            required: {
                                                value: true,
                                                message: t("auth.email") + " " + t("required")
                                            },
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: t("auth.email") + " " + t("must_be_valid")
                                            },
                                            minLength: {
                                                value: 7,
                                                message: "7 " + t("characters_minimum")
                                            },
                                            maxLength: {
                                                value: 64,
                                                message: "64 " + t("characters_maximum")
                                            }
                                        })}
                                    required
                                />
                                <FormInputError error={errors.email} />
                            </div>
                        </fieldset>

                        {/* *** pass ******************************************************** */}
                        <fieldset className='row mb-1'>
                            <label className='col-3' htmlFor="password">
                                {t("auth.password")}
                            </label>
                            <div className="col-9">
                                <input
                                    id="password"
                                    type="password"
                                    {...register("password", {
                                        required: {
                                            value: true,
                                            message: t("auth.password") + " " + t("required")
                                        },
                                        minLength: {
                                            value: 7,
                                            message: "7 " + t("characters_minimum")
                                        }
                                    })}
                                    required
                                />

                                <FormInputError error={errors.password} />

                                {password &&
                                    <div className='p-2 bkg-color-white rounded-bottom'>
                                        <PasswordStrengthBar
                                            password={password}
                                            onChangeScore={onChangeScore}
                                            minLength={7}
                                            scoreWords={[
                                                '',
                                                'too simple : use number, point, dash ...',
                                                'too simple : use number, point, dash ...',
                                                'good',
                                                'very secured'
                                            ]}
                                        />
                                    </div>
                                }
                            </div>
                        </fieldset>
                        {/* *** pass retype ******************************************************** */}
                        <fieldset className='row mb-1'>
                            <label className='col-3' htmlFor="name">
                                {t("retype_password")}
                            </label>
                            <div className="col-9">
                                <input
                                    id="password_retype"
                                    type="password"
                                    {...register("password_retype",
                                        {
                                            required: {
                                                value: true,
                                                message: t("auth.password") + " " + t("required")
                                            },
                                            validate: value =>
                                                value === passwordRef.current || "Needs to be identical as password"
                                        })}
                                    required />
                                <FormInputError error={errors.password_retype} />
                            </div>
                        </fieldset>
                        {/* *********************************************************** */}
                        <div className="row mt-3">
                            <div className="col">
                                <button
                                    type="submit"
                                    className='button-custom'
                                    disabled={!isValid}
                                >
                                    {t("auth.signup")}
                                </button>
                            </div>
                        </div>
                        {
                            isSubmitted &&
                            <Loader cssClass="loader-sm" />
                        }
                    </form>
                    <div className="row">
                        <div className="col mt-2">
                            <Captcha onChange={onCaptchaChange} />
                        </div>
                    </div>
                </PageLayout>
            }
        </>
    );
}

export default Register;