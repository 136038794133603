import img_en from '../image/language/Royal_Coat_of_Arms_of_the_United_Kingdom.png';
import img_fr from '../image/language/Middle_coat_of_arms_of_the_French_Republic.png';
import img_es from '../image/language/Coat_of_Arms_of_Spain.png';
import LetterClass from './entity/LetterClass';
import LanguageClass from './entity/LanguageClass';
import RoundClass from './entity/RoundClass';
import UserData from './entity/UserDataClass';
import Word from './entity/WordClass';
import LevelClass from './entity/LevelClass';

export const ROUNDS_FREQUENCY_TO_PROMPT_REGISTRATION:number = 10;

export const ALPHABET:string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const LETTERS_FRENCH:string = "ÉÀÈÌÒÙÂÊÎÔÛÇËÏÜŒ";
export const LETTERS_SPANISH:string = "ÁÉÍÓÚÜÑ";
export const KEYBOARD_AMOUNT_OF_KEYS:number = 21;

export const LANGUAGES:Array<LanguageClass> = [
    new LanguageClass(1,"en", "english", "in", img_en, { "words": "words" } ),
    new LanguageClass(2,"fr", "français", "en", img_fr, { "words": "mots" } ),
    new LanguageClass(3,"es", "español", "en", img_es, { "words": "palabras" }  )
];

export const NAME_VALID:string = "Jean-Hervé";
export const NAME_UNVALID:string = "Jean Hervé";

export const EMAIL_VALID:string = "marc-planteagenet74@Best-Mail1066.co.uk";
export const EMAIL_UNVALID:string = "marc-planteagenet74@@@@@@@@@Best-Mail1066.co.uk";

export const PASS_VALID:string = "Abcdefgh123-_*";
export const PASS_UNVALID_1:string = "Abcdefgh123-_";
export const PASS_UNVALID_2:string = "aaaaaaaaaaaaa";

export const MESSAGE_VALID:string = "Salut, juste pour dire que je mange ddu maïs à 20€. \n à la ligne.";

export const TOKEN:string = "e654gr1e564tg15r4t";

export const LETTERS_SHORTEST_WORD_PRISTINE:LetterClass[] = [
    new LetterClass("a"),
    new LetterClass("b")
];

export const LETTERS_SHORT_WORD_PRISTINE:LetterClass[] = [
    new LetterClass("m"),
    new LetterClass("a"),
    new LetterClass("ï"),
    new LetterClass("s")
];

export const LETTERS_LONG_WORD_MIXED_STATUS:LetterClass[] = [
    // Board should show  : A C L É O G M E
    new LetterClass("a"),
    new LetterClass("c", "lost"),
    new LetterClass("c", "won"),
    new LetterClass("é"),
    new LetterClass("l", "lost"),
    new LetterClass("é", "won"),
    new LetterClass("r"),
    new LetterClass("o", "lost"),
    new LetterClass("g", "won"),
    new LetterClass("r"),
    new LetterClass("a", "lost"),
    new LetterClass("m", "won"),
    new LetterClass("m", "won"),
    new LetterClass("e", "won")
];


export const LEVEL:LevelClass = new LevelClass(3, "🐤", "chick", "fff644", 40, 40, 20, 0);
export const LEVEL_HIGHEST:LevelClass = new LevelClass(222, "🐳", "whale", "4097aa", 0, 0, 20, 80);
// export const LEVEL = new LevelClass(3, "ICON", "chick", "fff644", 40, 40, 20, 0);
// export const LEVEL_HIGHEST = new LevelClass(222, "ICON", "whale", "4097aa", 0, 0, 20, 80);

export const WORD:Word = new Word(4169, "MAÏS", false, false, 1, LANGUAGES[1]);
export const WORD_ALT1:Word = new Word(1109, "BEAR", false, false, 1, LANGUAGES[0]);

export const ROUND:RoundClass = new RoundClass(9191, WORD);
export const ROUND_ALT1:RoundClass = new RoundClass(9191, WORD_ALT1);

export const USER_DATA:UserData = new UserData(1111, 2, false, 1212, 1313, 1414, 50, 3, 7, 50, 111, LEVEL, 222, LEVEL_HIGHEST);
export const USER_DATA_ALT1:UserData = new UserData(2121, 3, false, 2222, 2323, 2424, 50, 3, 7, 50, 111, LEVEL, 222, LEVEL_HIGHEST);

export const CAPTCHA_CONTAINER_ID:string = "g-recaptcha-container";