import { useState, useMemo, useEffect, useCallback } from "react";
import * as UtilService from '../../service/UtilService'
import LetterClass from '../../entity/LetterClass';
import KeyboardKeyClass from '../../entity/KeyboardKeyClass';
import KeyboardKey from './KeyboardKey';
import ConditionalLinkWrapper from '../ConditionalLinkWrapper';
// import * as C from '../../Constants'
import {KEYBOARD_AMOUNT_OF_KEYS, LETTERS_FRENCH, LETTERS_SPANISH, ALPHABET} from '../../Constants'
import TileType from '../../type/TileType';
import LanguageClass from "../../entity/LanguageClass.js";
import { useTranslation } from "react-i18next";
import * as LetterService from '../../service/LetterService'

interface KeyboardProps {
    roundId: number,
    // isFirstRoundKeyboardClicked: boolean,
    wordLanguage: LanguageClass,
    isRoundOver: boolean,
    letters: LetterClass[],
    // lettersIdsUnveiled: number[],
    handleKeyboardKeyClick: any,
    handleKeyDownEnter: any
}

function Keyboard({
    roundId,
    // isFirstRoundKeyboardClicked,
    wordLanguage,
    isRoundOver,
    letters,
    // lettersIdsUnveiled,
    handleKeyboardKeyClick,
    handleKeyDownEnter
}: KeyboardProps): JSX.Element {
    const { t } = useTranslation();

    const threeFrenchLetterClass = useMemo(() => getThreeRandomLetterClass("fr"), [roundId, getThreeRandomLetterClass]);
    const threeSpanishLetterClass = useMemo(() => getThreeRandomLetterClass("es"), [roundId, getThreeRandomLetterClass]);
    const [keyboardKeys, setKeyboardKeys] = useState<KeyboardKeyClass[]>(getInitialKeys());
    const lettersUnveiled = useMemo(() => LetterService.getUnveiled(letters), [letters]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [letters]);


    useEffect(() => {
        // to handle tile unveiled with gems, since letters changed
        lettersUnveiled.length &&
            setKeysPressedFromTileUnveiled(UtilService.getLastOfArray(lettersUnveiled))
    }, [lettersUnveiled]);
    // ****************************************************************************
    const handleKeyPress = useCallback(
        (event: any) => {
            handlePhysicalKeyboardPressed(event);
        }, [letters]);

    // ****************************************************************************
    const getRandomInt = useCallback(
        () => {
            return UtilService.getRandomInt(1, 20)
        }, []);

    // ****************************************************************************
    const setKeyboardKeyChanged = (index: number, keyboardKey: KeyboardKeyClass) => {
        let letterIsInWord: boolean = isLetterClassInWord(keyboardKey.content);
        const status: TileType = letterIsInWord ? "won" : "lost";
        keyboardKeys[index].status = status;
        keyboardKeys[index].isPressed = true;
        setKeyboardKeys(keyboardKeys);
        handleKeyboardKeyClick(keyboardKey, status);
        // if tuto not done, here is it
        // if (user.) {

        // }
    };

    // ****************************************************************************
    const setKeysPressedFromTileUnveiled = useCallback((letterUnveiled: LetterClass) => {
        // get last of uinveiled tile, cause the action is about it
        for (var i in keyboardKeys) {
            (letterUnveiled.content === keyboardKeys[i].content) &&
                (keyboardKeys[i].status = "lost") &&
                (keyboardKeys[i].isPressed = true)
        }
        setKeyboardKeys(keyboardKeys);
    }, [letters, keyboardKeys, lettersUnveiled]);

    // ****************************************************************************

    function handlePhysicalKeyboardPressed({ key }: any): void {
        // const k = event.key;
        if (key !== "Dead") {
            const kUpperCased: string = key.toUpperCase();
            const indexContainingKey = keyboardKeys.map(keyboardKey => keyboardKey.content).indexOf(kUpperCased);
            const isKeyInKeyboard = (indexContainingKey > -1)
            if (isKeyInKeyboard) {
                const keyboardKey: KeyboardKeyClass = keyboardKeys[indexContainingKey];
                // keyboard key has not to be already clicked or pressed
                (keyboardKey.status === "pristine") &&
                    setKeyboardKeyChanged(indexContainingKey, keyboardKey)
            } else if (key === "Enter" && isRoundOver) {
                // new round
                handleKeyDownEnter();
            }
        }
    }

    // ****************************************************************************
    function getKeysNoDuplicates(keys: KeyboardKeyClass[]): KeyboardKeyClass[] {
        const contents = keys.map(({ content }) => content);
        const lettersNoDuplicates = keys.filter(({ content }, index) => !contents.includes(content, index + 1));
        return lettersNoDuplicates;
    }

    // ****************************************************************************
    function getRandomLetterClass(iso: string): string {
        let l: string;
        if (iso === "fr") {
            l = LETTERS_FRENCH;
        } else if (iso === "es") {
            l = LETTERS_SPANISH;
        } else {
            throw "iso wrong value"
        }
        let randomKey = Math.floor(Math.random() * l.length);
        return l[randomKey];
    }

    // ****************************************************************************
    function getThreeRandomLetterClass(iso: string): string {
        return getRandomLetterClass(iso) + getRandomLetterClass(iso) + getRandomLetterClass(iso);
    }


    // ****************************************************************************
    function getInitialKeys(): KeyboardKeyClass[] {
        let keys: KeyboardKeyClass[] = [];
        letters.forEach((l: LetterClass) => {
            keys.push(
                new KeyboardKeyClass(l.content)
            );
        });

        keys = getKeysNoDuplicates(keys);
        while (keys.length < KEYBOARD_AMOUNT_OF_KEYS) {

            let letters: string =
                ((wordLanguage.codeIso639 === "fr") && ALPHABET + threeFrenchLetterClass) ||
                ((wordLanguage.codeIso639 === "es") && ALPHABET + threeSpanishLetterClass) ||
                ALPHABET;

            let randomLetterClassFromAlphabet: string = UtilService.getRandomCharacterFromString(letters)
            // push if not exist
            let doesExist = keys.some(e => e.content === randomLetterClassFromAlphabet);
            (!doesExist) &&
                keys.push(new KeyboardKeyClass(randomLetterClassFromAlphabet));

        }
        const compareByContent = (a: KeyboardKeyClass, b: KeyboardKeyClass) =>
            a.content.localeCompare(b.content);

        keys.sort(compareByContent);
        return keys;
    }

    // ****************************************************************************
    const isLetterClassInWord = (letter: string) =>
        letters.some(l => l.content === letter);


    // ****************************************************************************

    function handleClick(e: any, index: number, keyboardKey: KeyboardKeyClass) {
        setKeyboardKeyChanged(index, keyboardKey);
        e.preventDefault();
        return false;
    }
    // ****************************************************************************
    return (
        <>
            <div className={' keyboard-container '}>
                <div className="keyboard-tutorial">
                    <div>
                        {t("game.keyboard_to_guess")}
                        <div className="icon">
                        ↓
                        </div>
                    </div>
                </div>
                <ul className={' keyboard '} data-testid="keyboard">
                    {keyboardKeys.map((keyboardKey: KeyboardKeyClass, index: number) => {
                        return <li
                            key={index}
                            className={
                                " keyboard-key" +
                                " keyboard-key-status-" + keyboardKey.status +
                                " keyboard-key-random-int-" + getRandomInt()
                            }
                        >
                            <ConditionalLinkWrapper
                                isClickable={!keyboardKey.isPressed}
                                handleClick={(e: Event) => { handleClick(e, index, keyboardKey) }}
                            >
                                <KeyboardKey
                                    letter={keyboardKey.content}
                                    status={keyboardKey.status}
                                />
                            </ConditionalLinkWrapper>
                        </li>
                    })}
                </ul>
            </div>
        </>
    )
}
export default Keyboard;