import { useState, useEffect, useContext, useRef } from 'react';
import { Link } from "react-router-dom";
import { ApiContext } from '../context/ApiContext';
import NavigationItem from './NavigationItem';
import RouteHasChanged from './RouteHasChanged';
import LanguageSelectorSmall from './LanguageSelectorSmall';
import '../i18n';
import { useTranslation } from "react-i18next";
import { LanguageContext } from '../context/LanguageContext';
import * as UtilService from '../service/UtilService'
import cross from '../../image/cross-white.svg';
import burgerMenu from '../../image/burger-menu.svg';
import img_play from '../../image/navigation-icon/play.png';
import img_user from '../../image/navigation-icon/user.png';
import img_progression from '../../image/navigation-icon/progression.png';
import img_help from '../../image/navigation-icon/help.png';
import img_share from '../../image/navigation-icon/share.png';
import * as UserService from '../service/UserService'

function Navbar() {
    const { user } = useContext(ApiContext);
    const { lang } = useContext(LanguageContext);
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState<Boolean>(false);
    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                ref.current && !ref.current.contains(event.target) && setIsExpanded(false)
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const getAuthLinkLabel = (): any =>
        <>
            {user && 
                UserService.isStatus(user, "logged-in-as-authenticated") ?
                <span>
                    {UserService.getEmailUsername(user).substring( 0 , 7) + "."}
                </span>
                :
                <span>
                    {t("auth.login")}
                </span>
            }
        </>
    

    return (
        <>

            <RouteHasChanged hasChanged={() => { setIsExpanded(false) }} />

            <nav className={"navbar-custom bg-dark navbar-expand-lg " + (isExpanded ? "expanded" : "")}>
                <div className="container-fluid">

                    <div className='navbar-custom-header'>

                        <div className='navbar-custom-brand'>
                            {/* <Link to={"" + lang} relative="route" className="navbar-custom-brand " > */}
                            <Link to={UtilService.getRelativeUrl(lang, "")} relative="route" className="navbar-custom-brand " >
                                LEXIAR
                            </Link>
                        </div>

                        <div className="navbar-custom-auth-and-toggler">

                            <div className='navbar-custom-auth'>
                                <Link 
                                to={UtilService.getRelativeUrl(lang, "auth")} 
                                relative="route" 
                                className="navigation-item line-height-min" >
                                    
                                    {user && getAuthLinkLabel()}
                                </Link>
                            </div>

                            <div className='toggler'>
                                <div 
                                    style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        opacity: .4
                                    }}
                                >
                                    <a
                                        href="#"
                                        className={"btn-icon " + (isExpanded ? "btn-cross btn-cross-page" : "btn-burger-menu")}
                                        onClick={(e) => {
                                            setIsExpanded(!isExpanded)
                                            e.preventDefault();
                                            return false;
                                        }
                                        }>
                                        {isExpanded ?
                                            <div className='collapser'>
                                                <img src={cross} alt="close nav bar" />
                                            </div>
                                            :
                                            <div className='expander'>
                                                <img src={burgerMenu} alt="expand nav bar" />
                                            </div>
                                        }
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="navbar-custom-content" >
                        <ul className="">

                            <NavigationItem icon={img_play} to="game-language-selector" >
                                {t("game.play")} !
                            </NavigationItem>

                            <NavigationItem icon={img_user} to="auth" >
                                {user && getAuthLinkLabel()}
                            </NavigationItem>

                            <NavigationItem icon={img_progression} to="levels" >
                                {t("level.level", { count: 2 })}
                            </NavigationItem>

                            <NavigationItem icon={img_help} to="help" >
                                {t("help")}
                            </NavigationItem>

                            {/* <NavigationItem icon={img_share} to="credits" >
                                {t("love")}
                            </NavigationItem> */}

                            <NavigationItem icon={img_share} to="contact" >
                                {t("contact")}
                            </NavigationItem>

                            <li className='mt-4' >
                                <LanguageSelectorSmall></LanguageSelectorSmall>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}
export default Navbar;