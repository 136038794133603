class LanguageClass {

    public id: number;
    public codeIso639: string;
    public label: string;
    public prefix: string;
    public image: any
    public t: any
   
    public constructor(
        id: number,
        codeIso639: string,
        label: string = "",
        prefix: string = "",
        image: any = "",
        t: any = ""
        ) {
        this.id = id;
        this.codeIso639 = codeIso639;
        this.label = label;
        this.prefix = prefix;
        this.image = image;
        this.t = t;
    }
}
export default LanguageClass;