import { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import PageLayout from '../../layouts/PageLayout';
import '../../i18n';
import { useTranslation } from "react-i18next";


function Help() {

    const { t } = useTranslation();

    return (
        <PageLayout title={t("credits")} isCap={true} pageName="help" hasClosingCross>



            <div className="row">
                <div className="col">
                    <p>
                        {t("credits_content")}
                    </p>
                    <p>
                        <a href="">
                            aaaaaa@gmail.com
                        </a>
                    </p>
                    <p>
                        version 1.0.0
                    </p>
                </div>
            </div>

        </PageLayout>
    );
}

export default Help;