import LevelClass from './LevelClass';

class UserDataClass {

    public user_id: number
    public language_id: number
    public is_tutorial_done: boolean
    public rounds_played_count: number
    public rounds_won_count: number
    public gems_count: number
    public victory_percentage: number;
    public level_step: number;
    public level_step_in_level: number;
    public level_step_in_level_percentage: number;
    public level_id: number;
    public level: LevelClass;
    public level_id_biggest_reached: number;
    public level_biggest_reached: LevelClass;

    public constructor(

        user_id: number,
        language_id: number,
        is_tutorial_done: boolean,
        rounds_played_count: number,
        rounds_won_count: number,
        gems_count: number,
        victory_percentage: number,
        level_step: number,
        level_step_in_level: number,
        level_step_in_level_percentage: number,
        level_id: number,
        level: LevelClass,
        level_id_biggest_reached: number,
        level_biggest_reached: LevelClass

    ) {
        
        this.user_id = user_id;
        this.language_id = language_id;
        this.is_tutorial_done = is_tutorial_done;
        this.rounds_played_count = rounds_played_count;
        this.rounds_won_count = rounds_won_count;
        this.gems_count = gems_count;

        this.level_step = level_step;
        this.level_step_in_level = level_step_in_level;
        this.level_step_in_level_percentage = level_step_in_level_percentage;

        this.level_id = level_id;
        this.level = level;

        this.level_id_biggest_reached = level_id_biggest_reached;
        this.level_biggest_reached = level_biggest_reached;

        this.victory_percentage = victory_percentage;
        
    }
}
export default UserDataClass;